// Dependencies

// axios
import { handleDataForLogin, handleDataForLogout } from "../utils";
import axiosInstance from "./client";

// const register = async (regisData: {
//   email: string;
//   username: string;
//   password: string;
// }) => {
//   const response = await axiosInstance.post(`/user/register`, regisData);
//   return response;
// };

const login = async (loginData) => {
  const response = await axiosInstance.post(`api/user/signin`, loginData);
  const refreshToken = response.data?.refresh_token;
  const accessToken = response.data?.access_token;
  const userData = {
    name: response.data?.username,
  };
  handleDataForLogin(userData, refreshToken, accessToken);
  return response;
};

// const emailConfirm = async (activationToken: string) => {
//   const response = await axiosInstance.get(
//     `/user/email-confirm?token=${activationToken}`
//   );

//   return response;
// };

// const getProfile = async () => {
//   const response = await axiosInstance.get(`/user/profile`);
//   return response;
// };

const changePassword = async ({ oldPassword, newPassword }) => {
  const response = await axiosInstance.post(`/api/user/change-password`, {
    old_password: oldPassword,
    new_password: newPassword,
  });
  return response;
};

const logout = async () => {
  await axiosInstance.delete(`api/user/logout`);

  handleDataForLogout();

  return undefined;
};

const forgotPassword = async (email) => {
  const response = await axiosInstance.post(`/api/user/forget-password`, {
    email,
  });
  return response;
};

const resetPassword = async ({ password, resetToken }) => {
  const response = await axiosInstance.post(`/api/user/reset-password`, {
    password: password,
    reset_token: resetToken,
  });
  return response;
};

export {
  login,
  //   register,
  //   emailConfirm,
  //   getProfile,
  changePassword,
  logout,
  forgotPassword,
  resetPassword,
};
