import React from "react";
import { useForm } from "react-hook-form";
// import "./style.css";
import { changePassword } from "../../services/auth";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";

const ChangePass = () => {
  const { enqueueSnackbar } = useSnackbar();

  const changePassMutation = useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      enqueueSnackbar("Change password success!", { variant: "success" });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });

  const handleSubmitForm = (values) => {
    if (values.newPassword !== values.newPasswordConfirm) {
      setError("newPasswordConfirm", {
        type: "mismatch",
        message: "Password does not match",
      });
    }
    if (Object.keys(errors).length === 0) {
      changePassMutation.mutate({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <h3 className="text-center">Change Password</h3>
      <div className="form-group row p-1">
        <label htmlFor="oldPassword" className="col-sm-4 col-form-label">
          Old Password
        </label>
        <div className="col-sm-12">
          <input
            type="password"
            placeholder="Enter Old Password"
            className="form-control"
            required
            {...register("oldPassword", {
              pattern: {
                value: /^(?=.*[0-9])(?=.*[A-Z])[a-zA-Z@$!%*?&0-9]*$/,
                message: "Password invalid",
              },
            })}
          ></input>
          {errors.oldPassword && <p>{errors.oldPassword.message}</p>}
        </div>
      </div>

      <div className="form-group row p-1">
        <label htmlFor="newPassword" className="col-sm-4 col-form-label">
          New Password
        </label>
        <div className="col-sm-12">
          <input
            type="password"
            placeholder="Enter New Password"
            className="form-control"
            required
            {...register("newPassword", {
              pattern: {
                value: /^(?=.*[0-9])(?=.*[A-Z])[a-zA-Z@$!%*?&0-9]*$/,
                message: "Password invalid",
              },
            })}
          ></input>
          {errors.newPassword && <p>{errors.newPassword.message}</p>}
        </div>
      </div>

      <div className="form-group row p-1">
        <label htmlFor="newPasswordConfirm" className="col-sm-4 col-form-label">
          Confirm New Password
        </label>
        <div className="col-sm-12">
          <input
            type="password"
            placeholder="Confirm New Password"
            className="form-control"
            required
            {...register("newPasswordConfirm", {})}
          ></input>
          {errors.newPasswordConfirm && (
            <p>{errors.newPasswordConfirm.message}</p>
          )}
        </div>
      </div>

      <div
        className="d-flex justify-content-center p-2"
        style={{ gap: "10px" }}
      >
        <button className="btn btn-primary">
          {changePassMutation.isLoading && (
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          &nbsp; Change Password
        </button>
      </div>
      {/* <p className="text-end mt-2">
            <a href=""> Forgot Password</a>
          </p> */}
    </form>
  );
};

export default ChangePass;
