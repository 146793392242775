import UpdateProductInfo from "../../components/Product/UpdateProductInfo";
import UpdateProductFiles from "../../components/Product/UpdateProductFiles/UpdateProductFiles";
import { useState } from "react";

function ProductDetail() {
  const [categoryId, setCategoryId] = useState(null);

  return (
    <div className="d-flex flex-column">
      <UpdateProductInfo
        categoryId={categoryId}
        setCategoryId={setCategoryId}
      />
      <UpdateProductFiles categoryId={categoryId} />
    </div>
  );
}

export default ProductDetail;
