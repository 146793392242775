import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { editUser, getRoles, getUserDetail } from "../../services/users";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useParams, useNavigate } from "react-router-dom";

function UserDetail() {
  const [birthDay, setBirthDay] = useState(null);
  const [origUsername, setOrigUsername] = useState(null);
  const [newUsername, setNewUsername] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    criteriaMode: "all",
  });

  const username = params.username;

  const userQuery = useQuery({
    queryKey: ["userDetail", username],
    queryFn: () => getUserDetail(username),
    staleTime: Infinity,
    onSuccess(data) {
      setValue("username", data.username);
      setValue("email", data.email);
      setValue("first_name", data.first_name);
      setValue("last_name", data.last_name);
      setValue("is_locked", data.is_locked);
      setValue("role_description", data.role_description);
      setBirthDay(data.date_of_birth);
      setOrigUsername(data.username);
      setNewUsername(data.username);
    },
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const userDetail = userQuery.data;

  const rolesQuery = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const rolesList = rolesQuery.data;

  const editUserMutation = useMutation({
    mutationFn: editUser,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Edit User success!", { variant: "success" });
      navigate(`/user/${newUsername}`);
      queryClient.invalidateQueries('userList')
    },
  });

  const handleSubmitForm = (values) => {
    let date = birthDay;

    if (date !== null) {
      if (typeof date !== "string") {
        date = birthDay.toISOString().split("T")[0];
      } else {
        date = birthDay.split("T")[0];
      }
    }
    const editData = {
      username: values.username,
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      date_of_birth: date,
      is_locked: values.is_locked,
      role_id: values.role_id,
    };

    editUserMutation.mutate({ username: origUsername, data: editData });
  };

  if (userQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <form
      style={{ maxWidth: "600px" }}
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <h3 className="text-center">User Detail</h3>

      <div className="form-group row p-1">
        <label htmlFor="username" className="col-sm-4 col-form-label">
          User Name:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="username"
            name="username"
            type="text"
            {...register("username")}
            onChange={(e) => setNewUsername(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="email" className="col-sm-4 col-form-label">
          Email:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="email"
            name="email"
            type="text"
            {...register("email")}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="first_name" className="col-sm-4 col-form-label">
          First name:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="first_name"
            name="first_name"
            type="text"
            {...register("first_name")}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="last_name" className="col-sm-4 col-form-label">
          Last name:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="last_name"
            name="last_name"
            type="text"
            {...register("last_name")}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="date_of_birth" className="col-sm-4 col-form-label">
          Date of birth:
        </label>
        <div className="col-sm-12 date" data-date-format="mm-dd-yyyy">
          <DatePicker
            className="form-control"
            id="date_of_birth"
            name="date_of_birth"
            selected={birthDay}
            onChange={(date) => setBirthDay(date)}
            dateFormat="yyyy-MM-dd"
          />
          <span className="input-group-addon">
            <i className="glyphicon glyphicon-calendar"></i>
          </span>
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="is_locked" className="col-sm-4 col-form-label">
          Locked:
        </label>
        <div className="col-sm-12">
          <input
            className="form-check-input"
            id="is_locked"
            name="is_locked"
            type="checkbox"
            {...register("is_locked")}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="role_id" className="col-sm-4 col-form-label">
          Role:
        </label>
        <div className="col-sm-12">
          <select
            className="form-select"
            id="role_id"
            name="role_id"
            required
            {...register("role_id")}
          >
            {rolesList?.map((op) => (
              <option key={op.role_id} value={op.role_id}>
                {op.description}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div
        className="d-flex justify-content-center p-2"
        style={{ gap: "10px" }}
      >
        <button type="submit" className="btn btn-primary">
          {editUserMutation.isLoading && (
            <span>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;
            </span>
          )}
          Submit
        </button>
        <button type="button" className="btn btn-danger">
          Cancel
        </button>
      </div>
    </form>
  );
}

export default UserDetail;
