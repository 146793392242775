import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteCategory, getCategoryList } from "../../services/categories";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";
import ProductImage from "../../components/Product/ProductImage";
import { useSnackbar } from "notistack";
import { Modal } from "react-bootstrap";
import { convertDate } from "../../utils";

const perPageOptions = [10, 20, 30];

const DeleteProductModal = ({ show, handleClose, handleDelete }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Category</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleDelete}
        >
          Confirm
        </button>
        <button type="button" className="btn btn-danger" onClick={handleClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

function Categories() {
  const [categoryId, setCategoryId] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    const categoryId = sessionStorage.getItem("category_id");
    setCategoryId(categoryId);
  }, []);

  useEffect(() => {
    // Save scroll position before page is unloaded
    window.addEventListener("scroll", () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    });

    // Cleanup
    return () => {
      window.removeEventListener("beforeunload", () => {
        sessionStorage.setItem("scrollPosition", window.scrollY);
      });
    };
  }, []);

  useEffect(() => {
    // Restore scroll position
    if (sessionStorage.getItem("scrollPosition")) {
      window.scrollTo(0, sessionStorage.getItem("scrollPosition"));
    }
  });

  const categoryListQuery = useQuery({
    queryKey: ["categoryList"],
    queryFn: getCategoryList,
    onSuccess(data) {
      if (!categoryId) {
        setCategoryId(data[0].category_id);
      }
    },
  });

  const categoryList = categoryListQuery.data || [];

  const deleteCategoryMutation = useMutation({
    mutationFn: deleteCategory,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Delete Category success!", { variant: "success" });
      queryClient.invalidateQueries("categoryList");
      setShowDeleteModal(false);
    },
  });

  const handleRedirectAddCategory = () => {
    navigate(config.routes.addCategory);
  };

  const handleShowDetail = (id) => {
    navigate(`${config.routes.category}/${id}`);
  };
  const handleShowDeleteModal = (id) => {
    setShowDeleteModal(true);
    setDeleteCategoryId(id);
  };

  const handleDeleteCategory = () => {
    deleteCategoryMutation.mutate(deleteCategoryId);
  };

  return (
    <div>
      <h3 className="text-center">Category List</h3>
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleRedirectAddCategory}
        >
          Add Category
        </button>
      </div>
      <div>
        {categoryListQuery.isLoading ? (
          "Loading"
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Category Name</th>
                <th scope="col">Description</th>
                <th scope="col">Photo</th>
                <th scope="col">Order Num</th>
                <th scope="col">Last modified at</th>
                <th scope="col">Modified by</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {categoryList.map((category, index) => (
                <tr key={category.category_id}>
                  <th scope="row">{index + 1}</th>
                  <td onClick={() => handleShowDetail(category.category_id)}>
                    <Link>{category.category_name}</Link>
                  </td>
                  <td>{category.description}</td>
                  <td>
                    <ProductImage src={category.photo} />
                  </td>
                  <td>{category.order_num}</td>
                  <td>{convertDate(category.updated_at)}</td>
                  <td>{category.updated_by}</td>
                  <td>
                    <i
                      className="bi bi-trash3"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleShowDeleteModal(category.category_id)
                      }
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <DeleteProductModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDeleteCategory}
      />
    </div>
  );
}

export default Categories;
