import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { editProfile, getProfile } from "../../services/users";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useSnackbar } from "notistack";

function Profile() {
  const [birthDay, setBirthDay] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    criteriaMode: "all",
  });

  const profileQuery = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
    staleTime: Infinity,
    onSuccess(data) {
      setValue("username", data.username);
      setValue("email", data.email);
      setValue("first_name", data.first_name);
      setValue("last_name", data.last_name);
      setValue("is_locked", data.is_locked);
      setValue("role_description", data.role_description);
      setBirthDay(data.date_of_birth);
    },
  });

  const editProfileMutation = useMutation({
    mutationFn: editProfile,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Edit Profile success!", { variant: "success" });
      queryClient.invalidateQueries("profile");
      queryClient.invalidateQueries("userList");
    },
  });

  const handleSubmitForm = (values) => {
    let date = birthDay;
    if (typeof date !== "string") {
      date = birthDay.toISOString().split("T")[0];
    } else {
      date = birthDay.split("T")[0];
    }
    const editData = {
      first_name: values.first_name,
      last_name: values.last_name,
      date_of_birth: date,
    };

    editProfileMutation.mutate(editData);
  };

  return (
    <form
      style={{ maxWidth: "600px" }}
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <h3 className="text-center">Profile</h3>

      <div className="form-group row p-1">
        <label htmlFor="username" className="col-sm-4 col-form-label">
          User Name:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="username"
            name="username"
            type="text"
            disabled
            {...register("username")}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="email" className="col-sm-4 col-form-label">
          Email:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="email"
            name="email"
            type="text"
            {...register("email")}
            disabled
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="first_name" className="col-sm-4 col-form-label">
          First name:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="first_name"
            name="first_name"
            type="text"
            {...register("first_name")}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="last_name" className="col-sm-4 col-form-label">
          Last name:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="last_name"
            name="last_name"
            type="text"
            {...register("last_name")}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="date_of_birth" className="col-sm-4 col-form-label">
          Date of birth:
        </label>
        <div className="col-sm-12 date" data-date-format="mm-dd-yyyy">
          <DatePicker
            className="form-control"
            id="date_of_birth"
            name="date_of_birth"
            selected={birthDay}
            onChange={(date) => setBirthDay(date)}
            dateFormat="yyyy-MM-dd"
          />
          <span className="input-group-addon">
            <i className="glyphicon glyphicon-calendar"></i>
          </span>
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="is_locked" className="col-sm-4 col-form-label">
          Locked:
        </label>
        <div className="col-sm-12">
          <input
            className="form-check-input"
            id="is_locked"
            name="is_locked"
            type="checkbox"
            {...register("is_locked")}
            disabled
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="role_description" className="col-sm-4 col-form-label">
          Role:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="role_description"
            name="role_description"
            type="text"
            {...register("role_description")}
            disabled
          />
        </div>
      </div>
      <div
        className="d-flex justify-content-center p-2"
        style={{ gap: "10px" }}
      >
        <button type="submit" className="btn btn-primary">
          {editProfileMutation.isLoading && (
            <span>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;
            </span>
          )}
          Submit
        </button>
        <button type="button" className="btn btn-danger">
          Cancel
        </button>
      </div>
    </form>
  );
}

export default Profile;
