import React from "react";
import "bootstrap/js/dist/dropdown";
import { useMutation } from "react-query";
import { logout } from "../../../services/auth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import config from "../../../config";

function NavBar() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const userInfo = JSON.parse(localStorage.getItem("user-info"));
  const userName = userInfo?.name;

  const logoutMutation = useMutation({
    mutationFn: logout,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Logout success!", { variant: "success" });
      navigate(config.routes.login);
    },
  });

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="navbar-collapse" id="collapsibleNavId">
        <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="dropdownId"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {userName}
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownId">
              <a className="dropdown-item" href={config.routes.profile}>
                Profile
              </a>
              <a className="dropdown-item" href={config.routes.changePass}>
                Change Password
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={logoutMutation.mutate}
              >
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
