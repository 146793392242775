// Dependencies
import axios from "axios";
import { handleDataForRefreshToken } from "../utils/utils";
import { logout } from "./auth";
import { baseURL } from "./host";

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 240000,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access-token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    throw Error(error?.response?.data?.message);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      console.log("unauthorized");
      try {
        const refreshToken = localStorage.getItem("refresh-token");
        const response = await axios.post(`${baseURL}api/user/refresh-token`, {
          refresh_token: refreshToken,
        });
        handleDataForRefreshToken(response.data.access_token);
      } catch (error) {
        throw Error(error?.response?.data?.message);
      }
    }

    if (error.response.status === 303) {
      logout();
    }

    throw Error(error?.response?.data?.message);
  }
);

export default axiosInstance;
