import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getCategoryImageList } from "../../../services/categories";
import { updateProductPhotos } from "../../../services/products";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

function UpdateProductPhoto({ categoryId, photos = [], handleClose }) {
  const [currentImagePage, setCurrentImagePage] = useState(1);
  const [photosList, setPhotosList] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const params = useParams();

  const productId = params.id;

  const categoryImageListQuery = useQuery({
    queryKey: ["categoryImageList", currentImagePage, categoryId],
    queryFn: () => getCategoryImageList({ categoryId, page: currentImagePage }),
    staleTime: Infinity,
  });

  const categoryImageList = categoryImageListQuery.data || [];
  const hasImages = categoryImageList.length;

  const submitPhotosMutation = useMutation({
    mutationFn: updateProductPhotos,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess() {
      enqueueSnackbar("Update Photos success!", { variant: "success" });
      queryClient.invalidateQueries("product-detail");
      handleClose();
    },
  });

  const canUpdatePhotos = categoryImageList.filter(
    (img) => !photos.some((photo) => photo.photo_id === img.photo_id)
  );
  const handleIncreaseImagePage = () => {
    if (hasImages) {
      setCurrentImagePage((prev) => prev + 1);
    }
  };

  const handleDecreaseImagePage = () => {
    if (currentImagePage > 1) {
      setCurrentImagePage((prev) => prev - 1);
    }
  };

  const handleChooseImagePage = (page) => {
    if (hasImages || page < currentImagePage) {
      setCurrentImagePage(page);
    }
  };

  const handleChooseImage = (isChecked, index) => {
    const list = photosList;
    if (isChecked) {
      list.push(index);
      setPhotosList(list);
    } else {
      const newList = list.filter((item) => item !== index);
      setPhotosList(newList);
    }
  };

  const handleSubmitPhotos = () => {
    submitPhotosMutation.mutate({
      photos: photosList,
      product_id: productId,
    });
  };

  return (
    <div className="form-group row p-1">
      <h3 className="text-center">Update Photos</h3>

      <div className="">
        <div className="card">
          <div className="card-body d-flex flex-wrap">
            {canUpdatePhotos.map((image) => (
              <div
                key={image.photo_id}
                className="card"
                style={{
                  position: "relative",
                  width: "fit-content",
                  minWidth: "100px",
                }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={image.photo_id}
                  name={image.photo_id}
                  onChange={(event) =>
                    handleChooseImage(event.target.checked, image.photo_id)
                  }
                  style={{
                    position: "absolute",
                    right: "5px",
                  }}
                ></input>
                <img
                  src={image.url}
                  alt="..."
                  style={{ width: "100px" }}
                  className="img-thumbnail"
                />
              </div>
            ))}
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${currentImagePage === 1 && "disabled"}`}
                onClick={handleDecreaseImagePage}
              >
                <a className="page-link " href="#">
                  Previous
                </a>
              </li>
              <li
                className="page-item"
                onClick={() =>
                  handleChooseImagePage(
                    currentImagePage === 1 ? 1 : currentImagePage - 1
                  )
                }
              >
                <a
                  className={`page-link ${currentImagePage === 1 && "active"}`}
                  href="#"
                >
                  {currentImagePage === 1 ? "1" : currentImagePage - 1}
                </a>
              </li>
              <li
                className="page-item"
                onClick={() =>
                  handleChooseImagePage(
                    currentImagePage === 1 ? 2 : currentImagePage
                  )
                }
              >
                <a
                  className={`page-link ${currentImagePage !== 1 && "active"}`}
                  href="#"
                >
                  {currentImagePage === 1 ? "2" : currentImagePage}
                </a>
              </li>
              <li
                className="page-item"
                onClick={() =>
                  handleChooseImagePage(
                    currentImagePage === 1 ? 3 : currentImagePage + 1
                  )
                }
              >
                <a className={`page-link ${!hasImages && "disabled"}`} href="#">
                  {currentImagePage === 1 ? "3" : currentImagePage + 1}
                </a>
              </li>
              <li
                className={`page-item ${!hasImages && "disabled"}`}
                onClick={handleIncreaseImagePage}
              >
                <a className="page-link" href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="d-flex" style={{ padding: "20px", gap: "20px" }}>
        <button className="btn btn-primary" onClick={handleSubmitPhotos}>
          {submitPhotosMutation.isLoading && (
            <span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;
            </span>
          )}
          Submit
        </button>
        {/* <button className=" btn btn-danger">Cancel</button> */}
      </div>
    </div>
  );
}

export default UpdateProductPhoto;
