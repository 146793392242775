// Dependencies

// Components
import { SnackbarProvider, closeSnackbar } from "notistack";

const NotiStackWrapper = ({ children }) => {
  return (
    <SnackbarProvider
      Components={{}}
      //   action={(id) => (
      //     <StyledCloseButton onClick={() => closeSnackbar(id)}>
      //       close
      //     </StyledCloseButton>
      //   )}
      maxSnack={3}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotiStackWrapper;
