import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { uploadProductSmallPhoto } from "../../../services/products";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";

function UploadSmallPhoto({ handleClose }) {
  const [smallImage, setSmallImage] = useState(null);
  const [uploadSmallImage, setUploadSmallImage] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const params = useParams();
  const queryClient = useQueryClient();

  const productId = location.state?.productId || params.id;
  const submitSmallPhotoMutation = useMutation({
    mutationFn: uploadProductSmallPhoto,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess() {
      enqueueSnackbar("Add Small Photo success!", { variant: "success" });
      queryClient.invalidateQueries("product-detail");
      handleClose && handleClose();
    },
  });
  const handleUploadSmallImage = (event) => {
    const file = event.target.files[0];
    setUploadSmallImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSmallImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteSmallImage = () => {
    setSmallImage(null);
    setUploadSmallImage(null);
  };

  const handleSubmitSmallPhoto = () => {
    submitSmallPhotoMutation.mutate({
      data: uploadSmallImage,
      productId: productId,
    });
  };
  return (
    <div>
      <h3 className="text-center">Upload Small Photo</h3>
      <div
        className="card d-flex"
        style={{ minWidth: "18rem", minHeight: "4rem" }}
      >
        <div className="card-body d-flex flex-nowrap">
          {smallImage && (
            <div
              className="card"
              style={{
                position: "relative",
                padding: "10px",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              <i
                className="bi bi-x-circle"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  top: "-2px",
                  left: "2px",
                }}
                onClick={() => handleDeleteSmallImage()}
              ></i>
              <img
                src={smallImage}
                alt="..."
                style={{ width: "100px" }}
                className="img-thumbnail"
              />
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        accept=".webp"
        onChange={(event) => handleUploadSmallImage(event)}
      />
      <div className="d-flex" style={{ padding: "20px", gap: "20px" }}>
        <button className="btn btn-primary" onClick={handleSubmitSmallPhoto}>
          {submitSmallPhotoMutation.isLoading && (
            <span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;
            </span>
          )}
          Submit
        </button>
        {/* <button className=" btn btn-danger">Cancel</button> */}
      </div>
    </div>
  );
}

export default UploadSmallPhoto;
