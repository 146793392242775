import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { uploadProductDatasheet } from "../../../services/products";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";

function UploadDatasheet({ handleClose }) {
  const [datasheet, setDatasheet] = useState(null);
  const [uploadDatasheet, setUploadDatasheet] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const params = useParams();
  const queryClient = useQueryClient();

  const productId = location.state?.productId || params.id;
  const submitDatasheetMutation = useMutation({
    mutationFn: uploadProductDatasheet,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess() {
      enqueueSnackbar("Add Datasheet success!", { variant: "success" });
      queryClient.invalidateQueries("product-detail");
      handleClose && handleClose();
    },
  });

  const handleUploadDatasheet = (event) => {
    const file = event.target.files[0];
    setUploadDatasheet(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setDatasheet({ url: e.target.result, file_name: file.name });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteDatasheet = () => {
    setDatasheet(null);
    setUploadDatasheet(null);
  };

  const handleSubmitDatasheet = () => {
    submitDatasheetMutation.mutate({
      data: uploadDatasheet,
      productId: productId,
    });
  };
  return (
    <div>
      <h3 className="text-center">Upload Datasheet</h3>
      <div
        className="card d-flex"
        style={{ minWidth: "18rem", minHeight: "4rem" }}
      >
        <div className="card-body d-flex flex-nowrap">
          {datasheet && (
            <div
              className="card"
              style={{
                position: "relative",
                padding: "10px",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              <i
                className="bi bi-x-circle"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  top: "-2px",
                  left: "2px",
                }}
                onClick={() => handleDeleteDatasheet()}
              ></i>
              <a href={datasheet.url}>{datasheet.file_name}</a>
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        accept=".pdf"
        onChange={(event) => handleUploadDatasheet(event)}
      />
      <div className="d-flex" style={{ padding: "20px", gap: "20px" }}>
        <button className="btn btn-primary" onClick={handleSubmitDatasheet}>
          {submitDatasheetMutation.isLoading && (
            <span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;
            </span>
          )}
          Submit
        </button>
        {/* <button className=" btn btn-danger">Cancel</button> */}
      </div>
    </div>
  );
}

export default UploadDatasheet;
