import axiosInstance from "./client";

const getProfile = async () => {
  const response = await axiosInstance.get(`/api/user/profile`);

  return response.data;
};

const editProfile = async (data) => {
  const response = await axiosInstance.put(`/api/user/update`, data);

  return response.data;
};

const getUserList = async ({ page = 1, perPage = 10 }) => {
  const response = await axiosInstance.get(
    `/api/user/all?page=${page}&perPage=${perPage}`
  );

  return response.data;
};

const getUserDetail = async (username) => {
  const response = await axiosInstance.get(
    `/api/user/profile?username=${username}`
  );

  return response.data;
};

const editUser = async ({ username, data }) => {
  const response = await axiosInstance.put(
    `/api/user/update?username=${username}`,
    data
  );

  return response.data;
};

const getRoles = async () => {
  const response = await axiosInstance.get(`/api/role/all`);

  return response.data;
};

const addUser = async (data) => {
  const response = await axiosInstance.post(`/api/user/create`, data);

  return response.data;
};

const deleteUser = async (username) => {
  const response = await axiosInstance.delete(
    `/api/user/delete?username=${username}`
  );

  return response.data;
};


export {
  getProfile,
  editProfile,
  getUserList,
  getUserDetail,
  editUser,
  getRoles,
  addUser,
  deleteUser,
};
