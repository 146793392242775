import { useNavigate } from "react-router-dom";
import UploadDatasheet from "../../components/Product/UploadDatasheet";
import UploadPhotos from "../../components/Product/UploadPhotos";
import UploadSmallPhoto from "../../components/Product/UploadSmallPhoto/UploadSmallPhoto";
import config from "../../config";

function UploadProductImage() {
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-column">
      <UploadPhotos />
      <UploadSmallPhoto />
      <UploadDatasheet />
      <button
        onClick={() => navigate(config.routes.product)}
        className=" btn btn-danger"
        style={{ maxWidth: "100px" }}
      >
        Skip
      </button>
    </div>
  );
}

export default UploadProductImage;
