import UpdateCategoryInfo from "../../components/Category/UpdateCategoryInfo";
import UpdateCategoryPhoto from "../../components/Category/UpdateCategoryPhoto";

function CategoryDetail() {
  return (
    <div>
      <UpdateCategoryInfo />
      <UpdateCategoryPhoto />
    </div>
  );
}

export default CategoryDetail;
