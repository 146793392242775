import { useNavigate } from "react-router-dom";
import UploadPhoto from "../../components/Category/UploadPhoto";
import config from "../../config";

function UploadCategoryImage() {
  const navigate = useNavigate();
  return (
    <div>
      <UploadPhoto />;
      <button
        onClick={() => navigate(config.routes.category)}
        className=" btn btn-danger"
        style={{ maxWidth: "100px" }}
      >
        Skip
      </button>
    </div>
  );
}

export default UploadCategoryImage;
