import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";
import { useSnackbar } from "notistack";
import { Modal } from "react-bootstrap";
import { deleteUser, getUserList } from "../../services/users";

const perPageOptions = [10, 20, 30];

const DeleteUserModal = ({ show, handleClose, handleDelete }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleDelete}
        >
          Confirm
        </button>
        <button type="button" className="btn btn-danger" onClick={handleClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

function User() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageOptions[0]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUsername, setDeleteUsername] = useState(null);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Save scroll position before page is unloaded
    window.addEventListener("scroll", () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    });

    // Cleanup
    return () => {
      window.removeEventListener("beforeunload", () => {
        sessionStorage.setItem("scrollPosition", window.scrollY);
      });
    };
  }, []);

  useEffect(() => {
    // Restore scroll position
    if (sessionStorage.getItem("scrollPosition")) {
      window.scrollTo(0, sessionStorage.getItem("scrollPosition"));
    }
  });

  const userListQuery = useQuery({
    queryKey: ["userList", currentPage, perPage],
    refetchOnMount: true,
    queryFn: () =>
      getUserList({
        page: currentPage,
        perPage,
      }),
    onError(error) {
      navigate(-1);
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Delete User success!", { variant: "success" });
      queryClient.invalidateQueries("userList");
      setShowDeleteModal(false);
    },
  });

  const userList = userListQuery.data || [];
  const hasUsers = userList.length;

  //   const handleSelectCategory = (event) => {
  //     const id = event.target.value;
  //     setCategoryId(id);
  //     sessionStorage.setItem("category_id", id);
  //   };

  const handleIncreasePage = () => {
    if (hasUsers) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleDecreasePage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleChoosePage = (page) => {
    if (hasUsers || page < currentPage) {
      setCurrentPage(page);
    }
  };

  const handleSetPerPage = (event) => {
    const perPage = event.target.value;
    setPerPage(perPage);
  };

  const handleRedirectAddUser = () => {
    navigate(config.routes.addUser);
  };

  const handleShowDetail = (username) => {
    navigate(`${config.routes.users}/${username}`);
  };

  const handleShowDeleteModal = (id) => {
    setShowDeleteModal(true);
    setDeleteUsername(id);
  };

  const handleDeleteUser = () => {
    deleteUserMutation.mutate(deleteUsername);
  };

  return (
    <div>
      <h3 className="text-center">User List</h3>

      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleRedirectAddUser}
        >
          Add User
        </button>
        <select
          style={{ width: "100px" }}
          className="form-select mw-400"
          onChange={handleSetPerPage}
          value={perPage}
          id="perPage"
        >
          {perPageOptions.map((op) => (
            <option key={op} value={op}>
              {op}
            </option>
          ))}
        </select>
      </div>
      <div>
        {userListQuery.isLoading ? (
          "Loading"
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">User Name</th>
                <th scope="col">Email</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Date of birth</th>
                <th scope="col">Is locked</th>
                <th scope="col">Role</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {userList.map((user, index) => (
                <tr key={user.username}>
                  <th scope="row">{(currentPage - 1) * perPage + index + 1}</th>
                  <td onClick={() => handleShowDetail(user.username)}>
                    <Link>{user.username}</Link>
                  </td>
                  <td>{user.email}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.date_of_birth?.split("T")[0]}</td>
                  <td>
                    {user.is_locked && <i className="bi bi-check-circle"></i>}
                  </td>
                  <td>{user.role_description}</td>
                  <td>
                    <i
                      className="bi bi-trash3"
                      onClick={() => handleShowDeleteModal(user.username)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li
            className={`page-item ${currentPage === 1 && "disabled"}`}
            onClick={handleDecreasePage}
          >
            <a className="page-link " href="#">
              Previous
            </a>
          </li>
          <li
            class="page-item"
            onClick={() =>
              handleChoosePage(currentPage === 1 ? 1 : currentPage - 1)
            }
          >
            <a class={`page-link ${currentPage === 1 && "active"}`} href="#">
              {currentPage === 1 ? "1" : currentPage - 1}
            </a>
          </li>
          <li
            class="page-item"
            onClick={() =>
              handleChoosePage(currentPage === 1 ? 2 : currentPage)
            }
          >
            <a class={`page-link ${currentPage !== 1 && "active"}`} href="#">
              {currentPage === 1 ? "2" : currentPage}
            </a>
          </li>
          <li
            class="page-item"
            onClick={() =>
              handleChoosePage(currentPage === 1 ? 3 : currentPage + 1)
            }
          >
            <a class={`page-link ${!hasUsers && "disabled"}`} href="#">
              {currentPage === 1 ? "3" : currentPage + 1}
            </a>
          </li>
          <li
            class={`page-item ${!hasUsers && "disabled"}`}
            onClick={handleIncreasePage}
          >
            <a class="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>
      <DeleteUserModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDeleteUser}
      />
    </div>
  );
}

export default User;
