import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  deleteProductDatasheet,
  deleteProductPhoto,
  deleteProductSmallPhoto,
  getProductDetail,
} from "../../../services/products";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import UploadPhotos from "../UploadPhotos";
import UploadSmallPhoto from "../UploadSmallPhoto";
import UploadDatasheet from "../UploadDatasheet";
import { useSnackbar } from "notistack";
import UpdateProductPhoto from "../UpdateProductPhoto";
import UpdateProductSmallPhoto from "../UpdateProductSmallPhoto/UpdateProductSmallPhoto";
import UpdateProductDatasheet from "../UpdateProductDatasheet/UpdateProductDatasheet";

const PhotosUpload = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <UploadPhotos handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
};

const SmallPhotoUpload = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <UploadSmallPhoto handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
};

const DatasheetUpload = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <UploadDatasheet handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
};

function UpdateProductFiles({ categoryId }) {
  const [showPhotosModal, setShowPhotosModal] = useState(false);
  const [showSmallPhotoModal, setShowSmallPhotoModal] = useState(false);
  const [showDatasheetModal, setShowDatasheetModal] = useState(false);

  const [showPhotosUpload, setShowPhotosUpload] = useState(false);
  const [showSmallPhotoUpload, setShowSmallPhotoUpload] = useState(false);
  const [showDatasheetUpload, setShowDatasheetUpload] = useState(false);

  const [showPhotosUpdate, setShowPhotosUpdate] = useState(false);
  const [showSmallPhotoUpdate, setShowSmallPhotoUpdate] = useState(false);
  const [showDatasheetUpdate, setShowDatasheetUpdate] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const params = useParams();
  const productId = params.id;

  const productDetailQuery = useQuery({
    queryKey: ["product-detail", productId],
    queryFn: () => getProductDetail(productId),
    staleTime: Infinity,
    onSuccess(data) {},
  });
  const productDetail = productDetailQuery.data || {};

  const deletePhotoMutation = useMutation({
    mutationFn: deleteProductPhoto,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess() {
      enqueueSnackbar("Delete Photo success!", { variant: "success" });
      queryClient.invalidateQueries("product-detail");
    },
  });

  const deleteSmallPhotoMutation = useMutation({
    mutationFn: deleteProductSmallPhoto,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess() {
      enqueueSnackbar("Delete Small Photo success!", { variant: "success" });
      queryClient.invalidateQueries("product-detail");
    },
  });

  const deleteDatasheetMutation = useMutation({
    mutationFn: deleteProductDatasheet,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess() {
      enqueueSnackbar("Delete Datasheet success!", { variant: "success" });
      queryClient.invalidateQueries("product-detail");
    },
  });

  const handleUploadPhoto = () => {
    setShowPhotosUpload(true);
    setShowPhotosModal(false);
  };

  const handleUploadSmallPhoto = () => {
    setShowSmallPhotoUpload(true);
    setShowSmallPhotoModal(false);
  };

  const handleUploadDatasheet = () => {
    setShowDatasheetUpload(true);
    setShowDatasheetModal(false);
  };

  const handleUpdatePhoto = () => {
    setShowPhotosUpdate(true);
    setShowPhotosModal(false);
  };

  const handleUpdateSmallPhoto = () => {
    setShowSmallPhotoUpdate(true);
    setShowSmallPhotoModal(false);
  };

  const handleUpdateDatasheet = () => {
    setShowDatasheetUpdate(true);
    setShowDatasheetModal(false);
  };

  const PhotosModal = ({ show, handleClose }) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add photos</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleUpdatePhoto}
          >
            Add existing photos
          </button>
          <button
            onClick={handleUploadPhoto}
            type="button"
            className="btn btn-primary"
          >
            Upload photos
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SmallPhotoModal = ({ show, handleClose }) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add small photo</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleUpdateSmallPhoto}
          >
            Use an existing photo
          </button>
          <button
            onClick={handleUploadSmallPhoto}
            type="button"
            className="btn btn-primary"
          >
            Upload a photo
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DatasheetModal = ({ show, handleClose }) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add datasheet</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleUpdateDatasheet}
          >
            Use an existing file
          </button>
          <button
            onClick={handleUploadDatasheet}
            type="button"
            className="btn btn-primary"
          >
            Upload a file
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const PhotosUpdate = ({ show, handleClose }) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <UpdateProductPhoto
            categoryId={categoryId}
            photos={productDetail?.photos}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const SmallPhotoUpdate = ({ show, handleClose }) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <UpdateProductSmallPhoto
            categoryId={categoryId}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const DatasheetUpdate = ({ show, handleClose }) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <UpdateProductDatasheet
            categoryId={categoryId}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const handleDeletePhoto = (photoId) => {
    deletePhotoMutation.mutate({ productId, photoId });
  };

  return (
    <div style={{ maxWidth: "600px" }}>
      <div className="form-group row p-1">
        <label htmlFor="photos" className="col-sm-4 col-form-label">
          Photos:
        </label>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body d-flex align-items-center flex-wrap">
              {productDetail?.photos?.map((image) => (
                <div
                  key={image.photo_id}
                  className="card"
                  style={{
                    position: "relative",
                    width: "fit-content",
                    padding: "5px",
                    minWidth: "100px",
                  }}
                >
                  <i
                    className="bi bi-x-circle"
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      top: "-2px",
                      left: "2px",
                    }}
                    onClick={() => handleDeletePhoto(image.photo_id)}
                  ></i>
                  <img
                    src={image.photo_url}
                    alt="..."
                    style={{ width: "100px" }}
                    className="img-thumbnail"
                  />
                </div>
              ))}
              <i
                className="bi bi-plus-circle h2"
                style={{ marginLeft: "10px", cursor: "pointer" }}
                data-bs-target="#photo-add"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                onClick={() => setShowPhotosModal(true)}
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="photo_small" className="col-sm-4 col-form-label">
          Small photos:
        </label>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body d-flex flex-nowrap">
              {productDetail?.photo_small_id ? (
                <div
                  className="card"
                  style={{ position: "relative", width: "fit-content" }}
                >
                  <i
                    className="bi bi-x-circle"
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      top: "-2px",
                      left: "2px",
                    }}
                    onClick={() =>
                      deleteSmallPhotoMutation.mutate({ productId })
                    }
                  ></i>
                  <img
                    src={productDetail.photo_small_url}
                    alt="..."
                    style={{ width: "100px" }}
                    className="img-thumbnail"
                  />
                </div>
              ) : (
                <i
                  className="bi bi-plus-circle h2"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  data-bs-target="#smallphoto-add"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  onClick={() => setShowSmallPhotoModal(true)}
                ></i>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="datasheet" className="col-sm-4 col-form-label">
          Datasheet:
        </label>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body d-flex flex-nowrap">
              {productDetail?.datasheet_id ? (
                <div
                  key={productDetail.datasheet_id}
                  className="card"
                  id={productDetail.datasheet_id}
                  name={productDetail.datasheet_id}
                  style={{
                    position: "relative",
                    width: "100px",
                    height: "120px",
                    padding: "10px",
                    "text-overflow": "ellipsis",
                  }}
                >
                  <i
                    className="bi bi-x-circle"
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      top: "-2px",
                      left: "2px",
                    }}
                    onClick={() =>
                      deleteDatasheetMutation.mutate({ productId })
                    }
                  ></i>
                  <a href={productDetail.datasheet_url}>
                    {productDetail.datasheet_file_name}
                  </a>
                </div>
              ) : (
                <i
                  className="bi bi-plus-circle h2"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  data-bs-target="#datasheet-add"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  onClick={() => setShowDatasheetModal(true)}
                ></i>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Choose Modal*/}
      <PhotosModal
        show={showPhotosModal}
        handleClose={() => setShowPhotosModal(false)}
      />

      <SmallPhotoModal
        show={showSmallPhotoModal}
        handleClose={() => setShowSmallPhotoModal(false)}
      />

      <DatasheetModal
        show={showDatasheetModal}
        handleClose={() => setShowDatasheetModal}
      />

      {/* Upload Modal */}
      <PhotosUpload
        show={showPhotosUpload}
        handleClose={() => setShowPhotosUpload(false)}
      />

      <SmallPhotoUpload
        show={showSmallPhotoUpload}
        handleClose={() => setShowSmallPhotoUpload(false)}
      />

      <DatasheetUpload
        show={showDatasheetUpload}
        handleClose={() => setShowDatasheetUpload(false)}
      />

      {/* Update Modal */}
      <PhotosUpdate
        show={showPhotosUpdate}
        handleClose={() => setShowPhotosUpdate(false)}
      />

      <SmallPhotoUpdate
        show={showSmallPhotoUpdate}
        handleClose={() => setShowSmallPhotoUpdate(false)}
      />
      <DatasheetUpdate
        show={showDatasheetUpdate}
        handleClose={() => setShowDatasheetUpdate(false)}
      />
    </div>
  );
}

export default UpdateProductFiles;
