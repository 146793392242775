import React from "react";
import { useForm } from "react-hook-form";
import { resetPassword } from "../../services/auth";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
const ResetPass = () => {
  const { enqueueSnackbar } = useSnackbar();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const resetToken = searchParams.get("reset_token");

  const resetPassMutation = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      enqueueSnackbar("Change password success!", { variant: "success" });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });

  const handleSubmitForm = (values) => {
    if (values.password !== values.newPasswordConfirm) {
      setError("newPasswordConfirm", {
        type: "mismatch",
        message: "Password does not match",
      });
    } else if (Object.keys(errors).length === 0) {
      resetPassMutation.mutate({
        password: values.password,
        resetToken,
      });
    }
  };

  return (
    <div className="login d-flex justify-content-center align-items-center vh-100 bg-primary">
      <div className="form_container p-5 rounded bg-white">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <h3 className="text-center">Reset Password</h3>
          <div className="mb-2">
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              placeholder="Enter Password"
              className="form-control"
              required
              {...register("password", {
                pattern: {
                  value: /^(?=.*[0-9])(?=.*[A-Z])[a-zA-Z@$!%*?&0-9]*$/,
                  message: "Password invalid",
                },
              })}
            ></input>
            {errors.password && <p>{errors.password.message}</p>}
          </div>

          <div className="mb-2">
            <label htmlFor="newPasswordConfirm">Confirm New Password</label>
            <input
              type="password"
              placeholder="Confirm New Password"
              className="form-control"
              required
              {...register("newPasswordConfirm")}
            ></input>
            {errors.newPasswordConfirm && (
              <p>{errors.newPasswordConfirm.message}</p>
            )}
          </div>

          <div className="d-grid">
            <button className="btn btn-primary">
              {resetPassMutation.isLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              &nbsp; Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPass;
