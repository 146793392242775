import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getDeletedProductList,
  hardDeleteProduct,
  restoreProduct,
} from "../../services/products";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { useSnackbar } from "notistack";
import { Modal } from "react-bootstrap";
import ProductImage from "../../components/Product/ProductImage";

const perPageOptions = [10, 20, 30];

const DeleteProductModal = ({ show, handleClose, handleDelete }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Product</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleDelete}
        >
          Confirm
        </button>
        <button type="button" className="btn btn-danger" onClick={handleClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};
function DeletedProduct() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageOptions[0]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const productListQuery = useQuery({
    queryKey: ["deletedProductList", currentPage, perPage],
    queryFn: () =>
      getDeletedProductList({
        page: currentPage,
        perPage,
      }),
  });

  const deleteProductMutation = useMutation({
    mutationFn: hardDeleteProduct,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Delete Product success!", { variant: "success" });
      queryClient.invalidateQueries("deletedProductList");
      setShowDeleteModal(false);
    },
  });

  const restoreProductMutation = useMutation({
    mutationFn: restoreProduct,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Restore Product success!", { variant: "success" });
      queryClient.invalidateQueries("deletedProductList");
    },
  });

  const productList = productListQuery.data || [];
  const hasProducts = productList.length;

  const handleIncreasePage = () => {
    if (hasProducts) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleDecreasePage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleChoosePage = (page) => {
    if (hasProducts || page < currentPage) {
      setCurrentPage(page);
    }
  };

  const handleSetPerPage = (event) => {
    const perPage = event.target.value;
    setPerPage(perPage);
  };

  const handleShowDetail = (id) => {
    navigate(`${config.routes.product}/${id}`);
  };

  const handleShowDeleteModal = (id) => {
    setShowDeleteModal(true);
    setDeleteProductId(id);
  };

  const handleDeleteProduct = () => {
    deleteProductMutation.mutate(deleteProductId);
  };

  const handleRestoreProduct = (productId) => {
    restoreProductMutation.mutate(productId);
  };

  return (
    <div>
      <h3 className="text-center">Deleted Product</h3>
      <div className="d-flex align-items-center p-4"></div>
      <div className="d-flex justify-content-end">
        <select
          style={{ width: "100px" }}
          className="form-select mw-400"
          onChange={handleSetPerPage}
          value={perPage}
          id="perPage"
        >
          {perPageOptions.map((op) => (
            <option key={op} value={op}>
              {op}
            </option>
          ))}
        </select>
      </div>
      <div>
        {productListQuery.isLoading ? (
          "Loading"
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Part Number</th>
                <th scope="col">Description</th>
                <th scope="col">Photo</th>
                <th scope="col">Delete</th>
                <th scope="col">Restore</th>
              </tr>
            </thead>
            <tbody>
              {productList.map((product, index) => (
                <tr key={product.product_id} style={{ cursor: "pointer" }}>
                  <th scope="row">{(currentPage - 1) * perPage + index + 1}</th>
                  <td onClick={() => handleShowDetail(product.product_id)}>
                    {product.part_number}
                  </td>
                  <td>{product.description_short}</td>
                  <td>
                    <ProductImage src={product.photo_small_url} />
                  </td>
                  <td>
                    <i
                      className="bi bi-trash3"
                      onClick={() => handleShowDeleteModal(product.product_id)}
                    ></i>
                  </td>
                  <td>
                    <i
                      className="bi bi-arrow-repeat"
                      onClick={() => handleRestoreProduct(product.product_id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li
            className={`page-item ${currentPage === 1 && "disabled"}`}
            onClick={handleDecreasePage}
          >
            <a className="page-link " href="#">
              Previous
            </a>
          </li>
          <li
            class="page-item"
            onClick={() =>
              handleChoosePage(currentPage === 1 ? 1 : currentPage - 1)
            }
          >
            <a class={`page-link ${currentPage === 1 && "active"}`} href="#">
              {currentPage === 1 ? "1" : currentPage - 1}
            </a>
          </li>
          <li
            class="page-item"
            onClick={() =>
              handleChoosePage(currentPage === 1 ? 2 : currentPage)
            }
          >
            <a class={`page-link ${currentPage !== 1 && "active"}`} href="#">
              {currentPage === 1 ? "2" : currentPage}
            </a>
          </li>
          <li
            class="page-item"
            onClick={() =>
              handleChoosePage(currentPage === 1 ? 3 : currentPage + 1)
            }
          >
            <a class={`page-link ${!hasProducts && "disabled"}`} href="#">
              {currentPage === 1 ? "3" : currentPage + 1}
            </a>
          </li>
          <li
            class={`page-item ${!hasProducts && "disabled"}`}
            onClick={handleIncreasePage}
          >
            <a class="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>
      <DeleteProductModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDeleteProduct}
      />
    </div>
  );
}

export default DeletedProduct;
