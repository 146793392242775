import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../../../config/routes";
import logo from "../../../assets/images/Default.webp";

function SideBar() {
  const [show, setShow] = useState(false);
  return (
    <div className="bg-white sidebar p-2">
      <a className="m-2" href="/">
        <img src={logo} style={{ height: "50px" }} alt="logo" />
        <span className="brand-name fs-4">EC-Devices Admin</span>
      </a>
      <hr className="text-dark" />
      <div className="list-group list-group-flush">
        <NavLink
          to={routes.product}
          className="list-group-item collapsed py-2 "
        >
          <i className="bi bi-table fs-5 me-3"></i>
          <span className="fs-5">Product</span>
        </NavLink>
        <NavLink to={routes.category} className="list-group-item py-2">
          <i className="bi bi-card-list fs-5 me-3"></i>
          <span className="fs-5">Category</span>
        </NavLink>

        <NavLink to={routes.users} className="list-group-item py-2">
          <i className="bi bi-people fs-5 me-3"></i>
          <span className="fs-5">Users</span>
        </NavLink>
        <div
          className="list-group-item py-2 dropdown-toggle"
          data-toggle="collapse"
          aria-expanded="false"
          onClick={() => setShow(!show)}
          style={{ cursor: "pointer" }}
        >
          <i className="bi bi-trash fs-5 me-3"></i>
          <span className="fs-5">Recycle Bin</span>
        </div>

        <ul
          className={`collapse list-unstyled ${show ? "show" : ""}`}
          id="homeSubmenu"
        >
          <NavLink
            to={routes.deletedProduct}
            className="list-group-item py-2 "
            data-toggle="collapse"
            aria-expanded="false"
          >
            Deleted Product
          </NavLink>
          <NavLink to={routes.deletedCategory} className="list-group-item py-2">
            Deleted Category
          </NavLink>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
