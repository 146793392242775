import React from "react";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../../services/auth";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
const ForgotPass = () => {
  const { enqueueSnackbar } = useSnackbar();

  const forgotPassMutation = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      enqueueSnackbar("Send email success!", { variant: "success" });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });

  const handleSubmitForm = (values) => {
    if (Object.keys(errors).length === 0) {
      forgotPassMutation.mutate(values.email);
    }
  };

  return (
    <div className="login d-flex justify-content-center align-items-center vh-100 bg-primary">
      <div className="form_container p-5 rounded bg-white">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <h3 className="text-center">Forgot Password</h3>
          <div className="mb-2">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              placeholder="Enter Email"
              className="form-control"
              required
              {...register("email", {
                pattern: {
                  value: /^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/,
                  message: "Email invalid",
                },
              })}
            ></input>
            {errors.email && <p>{errors.email.message}</p>}
          </div>

          <div className="d-grid">
            <button className="btn btn-primary">
              {forgotPassMutation.isLoading && (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              &nbsp; Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPass;
