import axiosInstance from "./client";

const getProductList = async ({ categoryId, page = 1, perPage = 10 }) => {
  const response = await axiosInstance.get(
    `/api/product/all?category_id=${categoryId}&page=${page}&perPage=${perPage}`
  );

  return response.data;
};

const searchProducts = async ({
  categoryId,
  page = 1,
  perPage = 10,
  keyword,
}) => {
  const response = await axiosInstance.get(
    `/api/product/search?category_id=${categoryId}&keyword=${keyword}&page=${page}&perPage=${[
      perPage,
    ]}`
  );

  return response.data;
};

const getDeletedProductList = async ({ page = 1, perPage = 10 }) => {
  const response = await axiosInstance.get(
    `/api/product/deleted?page=${page}&perPage=${perPage}`
  );

  return response.data;
};

const addProduct = async (data) => {
  const productData = {
    category_id: data.category_id,
    part_number: data.part_number,
    description_short: data.description_short,
    description_full: data.description_full,
    specifications: data.specifications,
    lead_time_w: data.lead_time_w,
  };

  if (data.photos.length) {
    productData.photos = data.photos;
  }

  if (data.photo_small_id) {
    productData.photo_small_id = data.photo_small_id;
  }

  if (data.datasheet_id) {
    productData.datasheet_id = data.datasheet_id;
  }

  const response = await axiosInstance.post(`/api/product/create`, productData);

  return response.data;
};

const uploadProductPhotos = async ({ data, productId }) => {
  const formData = new FormData();

  for (let i = 0; i < data.length; i++) {
    formData.append("photos", data[i]);
  }

  const response = await axiosInstance.post(
    `/api/upload/product/photos?product_id=${productId}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return response.data;
};

const uploadProductSmallPhoto = async ({ data, productId }) => {
  const formData = new FormData();
  formData.append("photo_small", data);

  const response = await axiosInstance.post(
    `/api/upload/product/photo_small?product_id=${productId}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return response.data;
};

const uploadProductDatasheet = async ({ data, productId }) => {
  const formData = new FormData();
  formData.append("datasheet", data);

  const response = await axiosInstance.post(
    `/api/upload/product/datasheet?product_id=${productId}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return response.data;
};

const getProductDetail = async (id) => {
  const response = await axiosInstance.get(
    `/api/product/details?product_id=${id}`
  );

  return response.data;
};

const editProduct = async (data) => {
  const response = await axiosInstance.put(`/api/product/update`, data);

  return response.data;
};

const deleteProductPhoto = async ({ productId, photoId }) => {
  const response = await axiosInstance.delete(
    `/api/upload/product/photo?product_id=${productId}&photo_id=${photoId}`
  );

  return response.data;
};

const deleteProductSmallPhoto = async ({ productId }) => {
  const response = await axiosInstance.delete(
    `/api/upload/product/photo_small?product_id=${productId}`
  );

  return response.data;
};

const deleteProductDatasheet = async ({ productId }) => {
  const response = await axiosInstance.delete(
    `/api/upload/product/datasheet?product_id=${productId}`
  );

  return response.data;
};

const updateProductPhotos = async (data) => {
  const response = await axiosInstance.post(
    `/api/product/add-existing-photos`,
    data
  );

  return response.data;
};

const updateProductSmallPhoto = async (data) => {
  const response = await axiosInstance.post(
    `/api/product/add-existing-photo_small`,
    data
  );

  return response.data;
};

const updateProductDatasheet = async (data) => {
  const response = await axiosInstance.post(
    `/api/product/add-existing-datasheet`,
    data
  );

  return response.data;
};

const deleteProduct = async (productId) => {
  const response = await axiosInstance.delete(
    `/api/product/delete?product_id=${productId}`
  );

  return response.data;
};

const hardDeleteProduct = async (productId) => {
  const response = await axiosInstance.delete(
    `/api/product/delete-hard?product_id=${productId}`
  );

  return response.data;
};

const restoreProduct = async (productId) => {
  const response = await axiosInstance.post(
    `/api/product/restore?product_id=${productId}`
  );

  return response.data;
};

export {
  getProductList,
  getDeletedProductList,
  addProduct,
  uploadProductPhotos,
  uploadProductSmallPhoto,
  uploadProductDatasheet,
  getProductDetail,
  editProduct,
  deleteProductPhoto,
  deleteProductSmallPhoto,
  deleteProductDatasheet,
  updateProductPhotos,
  updateProductSmallPhoto,
  updateProductDatasheet,
  deleteProduct,
  hardDeleteProduct,
  restoreProduct,
  searchProducts,
};
