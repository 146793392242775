export const routes = {
  product: "/product",
  category: "/category",
  posts: "/posts",
  sliders: "/sliders",
  users: "/user",
  login: "/",
  changePass: "/change-password",
  forgotPass: "/forgot-password",
  resetPass: "reset-password",
  addProduct: "/product/add",
  uploadProductImage: "/product/upload-image",
  productDetail: "/product/:id",
  deletedProduct: "/deleted-product",
  categoryDetail: "/category/:id",
  addCategory: "/category/add",
  uploadCategoryImage: "/category/upload-image",
  deletedCategory: "/deleted-category",
  profile: "/profile",
  userDetail: "/user/:username",
  addUser: "/user/add",
};
