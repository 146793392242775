import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getCategoryDataSheetList } from "../../../services/categories";
import { updateProductDatasheet } from "../../../services/products";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

function UpdateProductDatasheet({ categoryId, handleClose }) {
  const [currentDatasheetPage, setCurrentDatasheetPage] = useState(1);
  const [dataSheet, setDataSheet] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const params = useParams();

  const productId = params.id;

  const categoryDataSheetListQuery = useQuery({
    queryKey: ["categoryDataSheetList", categoryId, currentDatasheetPage],
    queryFn: () =>
      getCategoryDataSheetList({ categoryId, page: currentDatasheetPage }),
    staleTime: Infinity,
  });

  const categoryDataSheetList = categoryDataSheetListQuery.data || [];
  const hasDatasheet = categoryDataSheetList.length;

  const submitDatasheetMutation = useMutation({
    mutationFn: updateProductDatasheet,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess() {
      enqueueSnackbar("Update Datasheet success!", { variant: "success" });
      queryClient.invalidateQueries("product-detail");
      handleClose();
    },
  });

  const handleIncreaseDatasheetPage = () => {
    if (hasDatasheet) {
      setCurrentDatasheetPage((prev) => prev + 1);
    }
  };

  const handleDecreaseDatasheetPage = () => {
    if (currentDatasheetPage > 1) {
      setCurrentDatasheetPage((prev) => prev - 1);
    }
  };

  const handleChooseDatasheetPage = (page) => {
    if (hasDatasheet || page < currentDatasheetPage) {
      setCurrentDatasheetPage(page);
    }
  };

  const handleChooseDatasheet = (isChecked, index) => {
    if (isChecked) {
      setDataSheet(index);
    } else {
      setDataSheet(null);
    }
  };

  const handleSubmitDatasheet = () => {
    submitDatasheetMutation.mutate({
      datasheet_id: dataSheet,
      product_id: productId,
    });
  };

  return (
    <div className="form-group row p-1">
      <h3 className="text-center">Update Datasheet</h3>

      <div className="col-sm-8">
        <div className="card">
          <div className="card-body d-flex flex-wrap">
            {categoryDataSheetList.map((pdf) => (
              <div
                key={pdf.datasheet_id}
                className="card"
                id={pdf.datasheet_id}
                name={pdf.datasheet_id}
                onChange={(event) =>
                  handleChooseDatasheet(event.target.checked, pdf.datasheet_id)
                }
                style={{
                  position: "relative",
                  width: "100px",
                  height: "120px",
                  padding: "5px",
                  "text-overflow": "ellipsis",
                }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  style={{
                    position: "absolute",
                    right: "5px",
                  }}
                ></input>
                <a href={pdf.url}>{pdf.file_name}</a>
              </div>
            ))}
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${
                  currentDatasheetPage === 1 && "disabled"
                }`}
                onClick={handleDecreaseDatasheetPage}
              >
                <a className="page-link " href="#">
                  Previous
                </a>
              </li>
              <li
                className="page-item"
                onClick={() =>
                  handleChooseDatasheetPage(
                    currentDatasheetPage === 1 ? 1 : currentDatasheetPage - 1
                  )
                }
              >
                <a
                  className={`page-link ${
                    currentDatasheetPage === 1 && "active"
                  }`}
                  href="#"
                >
                  {currentDatasheetPage === 1 ? "1" : currentDatasheetPage - 1}
                </a>
              </li>
              <li
                className="page-item"
                onClick={() =>
                  handleChooseDatasheetPage(
                    currentDatasheetPage === 1 ? 2 : currentDatasheetPage
                  )
                }
              >
                <a
                  className={`page-link ${
                    currentDatasheetPage !== 1 && "active"
                  }`}
                  href="#"
                >
                  {currentDatasheetPage === 1 ? "2" : currentDatasheetPage}
                </a>
              </li>
              <li
                className="page-item"
                onClick={() =>
                  handleChooseDatasheetPage(
                    currentDatasheetPage === 1 ? 3 : currentDatasheetPage + 1
                  )
                }
              >
                <a
                  className={`page-link ${!hasDatasheet && "disabled"}`}
                  href="#"
                >
                  {currentDatasheetPage === 1 ? "3" : currentDatasheetPage + 1}
                </a>
              </li>
              <li
                className={`page-item ${!hasDatasheet && "disabled"}`}
                onClick={handleIncreaseDatasheetPage}
              >
                <a className="page-link" href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="d-flex" style={{ padding: "20px", gap: "20px" }}>
        <button className="btn btn-primary" onClick={handleSubmitDatasheet}>
          {submitDatasheetMutation.isLoading && (
            <span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;
            </span>
          )}
          Submit
        </button>
        {/* <button className=" btn btn-danger">Cancel</button> */}
      </div>
    </div>
  );
}

export default UpdateProductDatasheet;
