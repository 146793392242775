import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {
  addCategory,
} from "../../services/categories";
import "react-quill/dist/quill.snow.css";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import config from "../../config";

function AddCategory() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });

  const addCategoryMutation = useMutation({
    mutationFn: addCategory,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Add Category success!", { variant: "success" });
      navigate(config.routes.uploadCategoryImage, {
        state: { categoryId: data.category_id },
      });
    },
  });

  const handleSubmitForm = (values) => {
    const addData = {
      description: values.description,
      category_name: values.name,
    };
    addCategoryMutation.mutate(addData);
  };

  return (
    <div>
      <form
        style={{ maxWidth: "600px" }}
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <h3 className="text-center">Add Category</h3>

        <div className="form-group row p-1">
          <label htmlFor="name" className="col-sm-4 col-form-label">
            Category Name:
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              id="name"
              name="name"
              type="text"
              required
              {...register("name")}
            />
          </div>
        </div>
        <div className="form-group row p-1">
          <label htmlFor="description" className="col-sm-4 col-form-label">
            Description:
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              id="description"
              name="description"
              type="text"
              {...register("description")}
              required
            />
          </div>
        </div>

        <div
          className="d-flex justify-content-center p-2"
          style={{ gap: "10px" }}
        >
          <button type="submit" className="btn btn-primary">
            {addCategoryMutation.isLoading && (
              <span>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                &nbsp;
              </span>
            )}
            Submit
          </button>
          <button type="button" className=" btn btn-danger">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddCategory;
