export const handleDataForLogin = (userData, refreshToken, accessToken) => {
  localStorage.setItem("user-info", JSON.stringify(userData));
  localStorage.setItem("refresh-token", refreshToken);
  handleDataForRefreshToken(accessToken);
};

export const handleDataForRefreshToken = (accessToken) => {
  localStorage.setItem("access-token", accessToken);
};

export const handleDataForLogout = () => {
  localStorage.removeItem("user-info");
  localStorage.removeItem("refresh-token");
  localStorage.removeItem("access-token");
};

export const quillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

export const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const convertDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  return "";
};
