import { Route, Routes } from "react-router-dom";
import { privateRoutes } from "../routes";
import SideBar from "../../components/Common/SideBar";
import NavBar from "../../components/Common/NavBar";
import { useEffect, useState } from "react";

function Layout() {
  const [currentScrollY, setCurrentScrollY] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setCurrentScrollY(window.scrollY);
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  // useLayoutEffect(() => {
  //   window.scrollTo(0, currentScrollY);
  // }, [currentScrollY]);

  return (
    <div>
      <NavBar />
      <div className="d-flex">
        <SideBar />
        <div className="flex-grow-1 p-4">
          <Routes>
            {privateRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                Component={route.component}
              />
            ))}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Layout;
