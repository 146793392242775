import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotiStackWrapper from "./components/Common/NotiStackWrapper";
import Login from "./pages/Login/Login";
import { privateRoutes, publicRoutes } from "./pages/routes";
import Layout from "./pages/Layout/Layout";

const App = () => {
  return (
    <NotiStackWrapper>
      <Router>
        <div className="App">
          <Routes>
            {publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                Component={route.component}
              />
            ))}
            <Route path="/*" Component={Layout} />
            {/* {privateRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                Component={route.component}
              />
            ))} */}
          </Routes>
        </div>
      </Router>
    </NotiStackWrapper>
  );
};

export default App;
