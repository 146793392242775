import axiosInstance from "./client";

const getCategoryList = async () => {
  const response = await axiosInstance.get(`/api/category/all`);

  return response.data;
};

const getDeletedCategoryList = async () => {
  const response = await axiosInstance.get(`/api/category/deleted`);

  return response.data;
};

const getCategoryImageList = async ({ categoryId, page = 1 }) => {
  const response = await axiosInstance.get(
    `/api/category/photos?category_id=${categoryId}&page=${page}&perPage=10`
  );

  return response.data;
};

const getCategorySmallImageList = async ({ categoryId, page = 1 }) => {
  const response = await axiosInstance.get(
    `/api/category/photo_small?category_id=${categoryId}&page=${page}&perPage=10`
  );

  return response.data;
};

const getCategoryDataSheetList = async ({ categoryId, page = 1 }) => {
  const response = await axiosInstance.get(
    `/api/category/datasheets?category_id=${categoryId}&page=${page}&perPage=10`
  );

  return response.data;
};

const getCategoryDetail = async (categoryId) => {
  const response = await axiosInstance.get(
    `/api/category/details?category_id=${categoryId}`
  );

  return response.data;
};

const editCategory = async (data) => {
  const response = await axiosInstance.put(`/api/category/update`, data);

  return response.data;
};

const uploadCategoryPhoto = async ({ data, categoryId }) => {
  const formData = new FormData();
  formData.append("photo", data);

  const response = await axiosInstance.post(
    `/api/upload/category/photo?category_id=${categoryId}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return response.data;
};

const deleteCategoryPhoto = async ({ categoryId, photoId }) => {
  const response = await axiosInstance.delete(
    `/api/upload/category/photo?category_id=${categoryId}`,
    {
      photo_id: photoId,
    }
  );

  return response.data;
};

const addCategory = async (data) => {
  const response = await axiosInstance.post(`/api/category/create`, data);

  return response.data;
};

const deleteCategory = async (categoryId) => {
  const response = await axiosInstance.delete(
    `/api/category/delete?category_id=${categoryId}`
  );

  return response.data;
};

const hardDeleteCategory = async (categoryId) => {
  const response = await axiosInstance.delete(
    `/api/category/delete-hard?category_id=${categoryId}`
  );

  return response.data;
};

const restoreCategory = async (categoryId) => {
  const response = await axiosInstance.post(
    `/api/category/restore?category_id=${categoryId}`
  );

  return response.data;
};

export {
  getCategoryList,
  getCategoryImageList,
  getCategorySmallImageList,
  getCategoryDataSheetList,
  getCategoryDetail,
  editCategory,
  uploadCategoryPhoto,
  deleteCategoryPhoto,
  addCategory,
  deleteCategory,
  getDeletedCategoryList,
  hardDeleteCategory,
  restoreCategory,
};
