import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Modal } from "react-bootstrap";
import ProductImage from "../../components/Product/ProductImage";
import {
  getDeletedCategoryList,
  hardDeleteCategory,
  restoreCategory,
} from "../../services/categories";

const DeleteProductModal = ({ show, handleClose, handleDelete }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Category</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleDelete}
        >
          Confirm
        </button>
        <button type="button" className="btn btn-danger" onClick={handleClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

function DeletedCategory() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const categorytListQuery = useQuery({
    queryKey: ["deletedCategoryList"],
    queryFn: () => getDeletedCategoryList(),
  });

  const deleteCategoryMutation = useMutation({
    mutationFn: hardDeleteCategory,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Delete Category success!", { variant: "success" });
      queryClient.invalidateQueries("deletedCategoryList");
      setShowDeleteModal(false);
    },
  });

  const restoreCategoryMutation = useMutation({
    mutationFn: restoreCategory,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Restore Category success!", { variant: "success" });
      queryClient.invalidateQueries("deletedCategoryList");
    },
  });

  const categoryList = categorytListQuery.data || [];

  const handleShowDeleteModal = (id) => {
    setShowDeleteModal(true);
    setDeleteCategoryId(id);
  };

  const handleDeleteProduct = () => {
    deleteCategoryMutation.mutate(deleteCategoryId);
  };

  const handleRestoreCategory = (categoryId) => {
    restoreCategoryMutation.mutate(categoryId);
  };

  return (
    <div>
      <h3 className="text-center">Deleted Category</h3>
      <div className="d-flex align-items-center p-4"></div>

      <div>
        {categorytListQuery.isLoading ? (
          "Loading"
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Category Name</th>
                <th scope="col">Description</th>
                <th scope="col">Photo</th>
                <th scope="col">Delete</th>
                <th scope="col">Restore</th>
              </tr>
            </thead>
            <tbody>
              {categoryList.map((category, index) => (
                <tr key={category.category_id}>
                  <th scope="row">{index + 1}</th>
                  <td>{category.category_name}</td>
                  <td>{category.description}</td>
                  <td>
                    <ProductImage src={category.photo} />
                  </td>
                  <td>
                    <i
                      className="bi bi-trash3"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleShowDeleteModal(category.category_id)
                      }
                    ></i>
                  </td>
                  <td>
                    <i
                      className="bi bi-arrow-repeat"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleRestoreCategory(category.category_id)
                      }
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <DeleteProductModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDeleteProduct}
      />
    </div>
  );
}

export default DeletedCategory;
