import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getCategorySmallImageList } from "../../../services/categories";
import {
  updateProductSmallPhoto,
} from "../../../services/products";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

function UpdateProductSmallPhoto({ categoryId, handleClose }) {
  const [currentSmallImagePage, setCurrentSmallImagePage] = useState(1);
  const [smallPhoto, setSmallPhoto] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const params = useParams();

  const productId = params.id;

  const categorySmallImageListQuery = useQuery({
    queryKey: ["categorySmallImageList", categoryId, currentSmallImagePage],
    queryFn: () =>
      getCategorySmallImageList({ categoryId, page: currentSmallImagePage }),
    staleTime: Infinity,
  });

  const categorySmallImageList = categorySmallImageListQuery.data || [];
  const hasSmallImages = categorySmallImageList.length;

  const submitPhotosMutation = useMutation({
    mutationFn: updateProductSmallPhoto,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess() {
      enqueueSnackbar("Update Small Photo success!", { variant: "success" });
      queryClient.invalidateQueries("product-detail");
      handleClose();
    },
  });

  const handleIncreaseSmallImagePage = () => {
    if (hasSmallImages) {
      setCurrentSmallImagePage((prev) => prev + 1);
    }
  };

  const handleDecreaseSmallImagePage = () => {
    if (currentSmallImagePage > 1) {
      setCurrentSmallImagePage((prev) => prev - 1);
    }
  };

  const handleChooseSmallImagePage = (page) => {
    if (hasSmallImages || page < currentSmallImagePage) {
      setCurrentSmallImagePage(page);
    }
  };

  const handleChooseSmallImage = (isChecked, index) => {
    console.log("check:", isChecked);
    console.log("index:", index);
    if (isChecked) {
      setSmallPhoto(index);
    } else {
      setSmallPhoto(null);
    }
  };

  const handleSubmitPhotos = () => {
    console.log("submit", smallPhoto);
    submitPhotosMutation.mutate({
      photo_small_id: smallPhoto,
      product_id: productId,
    });
  };

  return (
    <div className="form-group row p-1">
      <h3 className="text-center">Update Small Photo</h3>

      <div>
        <div className="card">
          <div className="card-body d-flex flex-wrap">
            {categorySmallImageList.map((image) => (
              <div
                key={image.photo_small_id}
                className="card"
                style={{
                  position: "relative",
                  width: "fit-content",
                  minWidth: "100px",
                }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={image.photo_small_id}
                  name={image.photo_small_id}
                  checked={image.photo_small_id === smallPhoto}
                  onChange={(event) =>
                    handleChooseSmallImage(
                      event.target.checked,
                      image.photo_small_id
                    )
                  }
                  style={{
                    position: "absolute",
                    right: "5px",
                  }}
                ></input>
                <img
                  src={image.url}
                  alt="..."
                  style={{ width: "100px" }}
                  className="img-thumbnail"
                />
              </div>
            ))}
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${
                  currentSmallImagePage === 1 && "disabled"
                }`}
                onClick={handleDecreaseSmallImagePage}
              >
                <a className="page-link " href="#">
                  Previous
                </a>
              </li>
              <li
                className="page-item"
                onClick={() =>
                  handleChooseSmallImagePage(
                    currentSmallImagePage === 1 ? 1 : currentSmallImagePage - 1
                  )
                }
              >
                <a
                  className={`page-link ${
                    currentSmallImagePage === 1 && "active"
                  }`}
                  href="#"
                >
                  {currentSmallImagePage === 1
                    ? "1"
                    : currentSmallImagePage - 1}
                </a>
              </li>
              <li
                className="page-item"
                onClick={() =>
                  handleChooseSmallImagePage(
                    currentSmallImagePage === 1 ? 2 : currentSmallImagePage
                  )
                }
              >
                <a
                  className={`page-link ${
                    currentSmallImagePage !== 1 && "active"
                  }`}
                  href="#"
                >
                  {currentSmallImagePage === 1 ? "2" : currentSmallImagePage}
                </a>
              </li>
              <li
                className="page-item"
                onClick={() =>
                  handleChooseSmallImagePage(
                    currentSmallImagePage === 1 ? 3 : currentSmallImagePage + 1
                  )
                }
              >
                <a
                  className={`page-link ${!hasSmallImages && "disabled"}`}
                  href="#"
                >
                  {currentSmallImagePage === 1
                    ? "3"
                    : currentSmallImagePage + 1}
                </a>
              </li>
              <li
                className={`page-item ${!hasSmallImages && "disabled"}`}
                onClick={handleIncreaseSmallImagePage}
              >
                <a className="page-link" href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="d-flex" style={{ padding: "20px", gap: "20px" }}>
        <button className="btn btn-primary" onClick={handleSubmitPhotos}>
        {submitPhotosMutation.isLoading && (
            <span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;
            </span>
          )}
          Submit
        </button>
        {/* <button className=" btn btn-danger">Cancel</button> */}
      </div>
    </div>
  );
}

export default UpdateProductSmallPhoto;
