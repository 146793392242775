import config from "../../config";
import Products from "../Products";

// Pages
import Login from "../Login";
import AddProduct from "../AddProduct";
import UploadProductImage from "../UploadProductImage";
import ProductDetail from "../ProductDetail";
import DeletedProduct from "../DeletedProduct";
import Categories from "../Categories";
import CategoryDetail from "../CategoryDetail";
import AddCategory from "../AddCategory";
import UploadCategoryImage from "../UploadCategoryImage";
import DeletedCategory from "../DeletedCategory";
import Profile from "../Profile";
import User from "../User";
import UserDetail from "../UserDetail";
import AddUser from "../AddUser";
import ChangePass from "../ChangePass";
import ForgotPass from "../ForgotPass";
import ResetPass from "../ResetPass";

export const publicRoutes = [
  { path: config.routes.login, component: Login },
  { path: config.routes.forgotPass, component: ForgotPass },
  { path: config.routes.resetPass, component: ResetPass },
];

export const privateRoutes = [
  { path: config.routes.changePass, component: ChangePass },
  { path: config.routes.product, component: Products },
  { path: config.routes.addProduct, component: AddProduct },
  { path: config.routes.uploadProductImage, component: UploadProductImage },
  { path: config.routes.productDetail, component: ProductDetail },
  { path: config.routes.deletedProduct, component: DeletedProduct },
  { path: config.routes.category, component: Categories },
  { path: config.routes.categoryDetail, component: CategoryDetail },
  { path: config.routes.addCategory, component: AddCategory },
  { path: config.routes.uploadCategoryImage, component: UploadCategoryImage },
  { path: config.routes.deletedCategory, component: DeletedCategory },
  { path: config.routes.profile, component: Profile },
  { path: config.routes.users, component: User },
  { path: config.routes.userDetail, component: UserDetail },
  { path: config.routes.addUser, component: AddUser },
];
