import { useState } from "react";
import ReactQuill from "react-quill";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { convertDate, quillFormats, quillModules } from "../../../utils";
import { getCategoryList } from "../../../services/categories";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { editProduct, getProductDetail } from "../../../services/products";

function UpdateProductInfo({ categoryId, setCategoryId }) {
  // Specifications
  const [specifications, setSpecifications] = useState([]);
  const [specificationCount, setSpecificationCount] = useState([]);
  // full description
  const [desc, setDesc] = useState("");
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    criteriaMode: "all",
  });

  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const productId = params.id;

  const categoryListQuery = useQuery({
    queryKey: ["categoryList"],
    queryFn: getCategoryList,
    refetchOnMount: true,
    staleTime: Infinity,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    // onSuccess(data) {
    //   setCategoryId(data[0].category_id);
    // },
  });

  const categoryList = categoryListQuery.data || [];

  const productDetailQuery = useQuery({
    queryKey: ["product-detail", productId],
    queryFn: () => getProductDetail(productId),
    staleTime: Infinity,
    onSuccess(data) {
      setDesc(data.description_full);
      setSpecificationCount(
        Array.from({ length: Object.entries(data.specifications).length })
      );
      Object.entries(data.specifications).forEach((spec) => {
        setSpecifications((prev) => [...prev, { [spec[0]]: spec[1] }]);
      });
      setValue("description_short", data.description_short);
      setValue("lead_time_w", data.lead_time_w);
      setValue("part_number", data.part_number);
      setValue("updated_by", data.updated_by);
      setValue("updated_at", convertDate(data.updated_at));
      setCategoryId(data.category_id);
    },
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const editProductMutation = useMutation({
    mutationFn: editProduct,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Edit Product success!", { variant: "success" });
      queryClient.invalidateQueries("product-detail");
    },
  });

  const handleSubmitForm = (values) => {
    const specs = specifications.reduce((accumulator, currentObject) => {
      return { ...accumulator, ...currentObject };
    }, {});
    const editData = {
      product_id: productId,
      category_id: categoryId,
      part_number: values.part_number,
      description_short: values.description_short,
      description_full: desc,
      specifications: specs,
      lead_time_w: values.lead_time_w,
    };
    editProductMutation.mutate(editData);
  };

  const handleSelectCategory = (event) => {
    const id = event.target.value;
    setCategoryId(id);
  };

  const handleAddSpecification = () => {
    setSpecifications((prev) => [...prev, {}]);
    setSpecificationCount((prev) => [...prev, new Date().getTime()]);
  };

  const handleRemoveSpecification = (index) => {
    specifications.splice(index, 1);
    specificationCount.splice(index, 1);
    setSpecifications([...specifications]);
    setSpecificationCount([...specificationCount]);
  };

  const handleChangeSpecificationAttribute = (event, index) => {
    const newAttribute = event.target.value;
    const spec = specifications[index];
    const oldAttribute = Object.keys(spec)[0];
    spec[newAttribute] = spec[oldAttribute];
    delete spec[oldAttribute];
    specifications[index] = spec;
    setSpecifications([...specifications]);
  };

  const handleChangeSpecificationValue = (event, index) => {
    const newValue = event.target.value;
    const spec = specifications[index];
    const attribute = Object.keys(spec)[0];

    spec[attribute] = newValue;

    specifications[index] = spec;
    setSpecifications([...specifications]);
  };

  return (
    <form
      style={{ maxWidth: "600px" }}
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <h3 className="text-center">Edit Product</h3>
      <div className="form-group row p-1">
        <label htmlFor="category_id" className="col-sm-4 col-form-label">
          Category:
        </label>
        <div className="col-sm-12">
          <select
            className="form-select"
            id="category_id"
            name="category_id"
            value={categoryId}
            required
            onChange={handleSelectCategory}
          >
            {categoryList.map((op) => (
              <option key={op.category_id} value={op.category_id}>
                {op.category_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="partNumber" className="col-sm-4 col-form-label">
          Part Number:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="partNumber"
            name="partNumber"
            type="text"
            required
            {...register("part_number")}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="description_short" className="col-sm-4 col-form-label">
          Short Description:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="description_short"
            name="description_short"
            type="text"
            {...register("description_short")}
            required
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="description_full" className="col-sm-4 col-form-label">
          Full Description:
        </label>
        <div className="col-sm-12">
          <ReactQuill
            className="form-control"
            theme="snow"
            modules={quillModules}
            formats={quillFormats}
            id="description_full"
            name="description_full"
            value={desc}
            onChange={(value) => {
              setDesc(value);
            }}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="specification" className="col-sm-4 col-form-label">
          Specifications:
        </label>
        <div className="col-sm-12">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleAddSpecification}
          >
            Add Specifications
          </button>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Attribute</th>
                <th scope="col">Value</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {specificationCount.map((id, index) => (
                <tr key={id}>
                  <td>
                    <input
                      value={Object.keys(specifications[index])[0]}
                      className="form-control"
                      id={`spec_attr_${index}`}
                      name={`spec_attr_${index}`}
                      onChange={(event) =>
                        handleChangeSpecificationAttribute(event, index)
                      }
                      required
                    />
                  </td>
                  <td>
                    <input
                      value={Object.values(specifications[index])[0]}
                      className="form-control"
                      id={`spec_value_${index}`}
                      name={`spec_value_${index}`}
                      onChange={(event) =>
                        handleChangeSpecificationValue(event, index)
                      }
                      required
                    />
                  </td>

                  <td>
                    <i
                      className="bi bi-trash3"
                      onClick={() => handleRemoveSpecification(index)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="leadTime" className="col-sm-4 col-form-label">
          Lead time (week):
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="leadTime"
            name="leadTime"
            type="number"
            {...register("lead_time_w")}
            required
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="updated_at" className="col-sm-4 col-form-label">
          Last modified by:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="updated_at"
            name="updated_at"
            type="text"
            disabled
            {...register("updated_at")}
            required
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="updated_by" className="col-sm-4 col-form-label">
          Modified by:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="updated_by"
            name="updated_by"
            type="text"
            disabled
            {...register("updated_by")}
            required
          />
        </div>
      </div>

      <div
        className="d-flex justify-content-center p-2"
        style={{ gap: "10px" }}
      >
        <button type="submit" className="btn btn-primary">
          {editProductMutation.isLoading && (
            <span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;
            </span>
          )}
          Submit
        </button>
        <button
          type="button"
          className=" btn btn-danger"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default UpdateProductInfo;
