import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { editCategory, getCategoryDetail } from "../../../services/categories";
import { convertDate } from "../../../utils";

function UpdateCategoryInfo() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    criteriaMode: "all",
  });

  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const categoryId = params.id;

  const categoryDetailQuery = useQuery({
    queryKey: ["category-detail", categoryId],
    queryFn: () => getCategoryDetail(categoryId),
    staleTime: Infinity,
    onSuccess(data) {
      setValue("description", data.description);
      setValue("name", data.category_name);
      setValue("order_num", data.order_num);
      setValue("updated_by", data.updated_by);
      setValue("updated_at", convertDate(data.updated_at));
    },
  });

  const editCategoryMutation = useMutation({
    mutationFn: editCategory,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Edit Category success!", { variant: "success" });
    },
  });

  const handleSubmitForm = (values) => {
    const editData = {
      category_id: categoryId,
      category_name: values.name,
      description: values.description,
      order_num: values.order_num,
    };
    editCategoryMutation.mutate(editData);
  };

  return (
    <form
      style={{ maxWidth: "600px" }}
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <h3 className="text-center">Edit Category</h3>

      <div className="form-group row p-1">
        <label htmlFor="partNumber" className="col-sm-4 col-form-label">
          Category Name:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="name"
            name="name"
            type="text"
            required
            {...register("name")}
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="description_short" className="col-sm-4 col-form-label">
          Description:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="description"
            name="description"
            type="text"
            {...register("description")}
            required
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="order_num" className="col-sm-4 col-form-label">
          Order num:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="order_num"
            name="order_num"
            type="number"
            min={0}
            {...register("order_num")}
            required
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="updated_at" className="col-sm-4 col-form-label">
          Last modified by:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="updated_at"
            name="updated_at"
            type="text"
            disabled
            {...register("updated_at")}
            required
          />
        </div>
      </div>
      <div className="form-group row p-1">
        <label htmlFor="updated_by" className="col-sm-4 col-form-label">
          Modified by:
        </label>
        <div className="col-sm-12">
          <input
            className="form-control"
            id="updated_by"
            name="updated_by"
            type="text"
            disabled
            {...register("updated_by")}
            required
          />
        </div>
      </div>
      <div
        className="d-flex justify-content-center p-2"
        style={{ gap: "10px" }}
      >
        <button type="submit" className="btn btn-primary">
          {editCategoryMutation.isLoading && (
            <span>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;
            </span>
          )}
          Submit
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default UpdateCategoryInfo;
