import { useForm } from "react-hook-form";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  deleteCategoryPhoto,
  getCategoryDetail,
} from "../../../services/categories";
import { Modal } from "react-bootstrap";
import UploadPhoto from "../UploadPhoto";

const PhotoUpload = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <UploadPhoto handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
};

function UpdateCategoryPhoto() {
  const [showPhotoUpload, setShowPhotoUpload] = useState(false);
  const {
    formState: { errors, isDirty },
  } = useForm({
    criteriaMode: "all",
  });

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const params = useParams();
  const categoryId = params.id;

  const categoryDetailQuery = useQuery({
    queryKey: ["category-detail", categoryId],
    queryFn: () => getCategoryDetail(categoryId),
    refetchOnMount: true,
    onSuccess(data) {},
  });

  const categoryDetail = categoryDetailQuery.data;

  const deletePhotoMutation = useMutation({
    mutationFn: deleteCategoryPhoto,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess() {
      enqueueSnackbar("Delete Photo success!", { variant: "success" });
      queryClient.invalidateQueries("category-detail");
    },
  });

  return (
    <div style={{ maxWidth: "600px" }}>
      <div className="form-group row p-1">
        <label htmlFor="photo_small" className="col-sm-4 col-form-label">
          Photo:
        </label>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body d-flex flex-nowrap">
              {categoryDetail?.photo_id ? (
                <div
                  className="card"
                  style={{ position: "relative", width: "fit-content" }}
                >
                  <i
                    className="bi bi-x-circle"
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      top: "-2px",
                      left: "2px",
                    }}
                    onClick={() =>
                      deletePhotoMutation.mutate({
                        categoryId,
                        photoId: categoryDetail.photo_id,
                      })
                    }
                  ></i>
                  <img
                    src={categoryDetail.photo}
                    alt="..."
                    style={{ width: "100px" }}
                    className="img-thumbnail"
                  />
                </div>
              ) : (
                <i
                  className="bi bi-plus-circle h2"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  data-bs-target="#smallphoto-add"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  onClick={() => setShowPhotoUpload(true)}
                ></i>
              )}
            </div>
          </div>
        </div>
      </div>

      <PhotoUpload
        show={showPhotoUpload}
        handleClose={() => setShowPhotoUpload(false)}
      />
    </div>
  );
}

export default UpdateCategoryPhoto;
