import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";
import { uploadProductPhotos } from "../../../services/products";

function UploadPhotos({ handleClose }) {
  const [images, setImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const location = useLocation();
  const params = useParams();

  const productId = location.state?.productId || params.id;
  const submitPhotosMutation = useMutation({
    mutationFn: uploadProductPhotos,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess() {
      enqueueSnackbar("Add Photos success!", { variant: "success" });
      queryClient.invalidateQueries("product-detail");
      handleClose && handleClose();
    },
  });
  const handleUploadImage = (event) => {
    const files = [...event.target.files];
    setUploadImages((prev) => [...prev, ...event.target.files]);

    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImages((prev) => [...prev, e.target.result]);
        };
        reader.readAsDataURL(files[i]);
      }
    }
  };

  const handleDeleteImage = (index) => {
    const newImages = images;
    newImages.splice(index, 1);
    setImages([...newImages]);

    const newUploads = uploadImages;
    newUploads.splice(index, 1);
    setUploadImages([...newUploads]);
  };

  const handleSubmitPhotos = () => {
    submitPhotosMutation.mutate({
      data: uploadImages,
      productId: productId,
    });
  };
  return (
    <div>
      <h3 className="text-center">Upload Photos</h3>
      <div
        className="card d-flex"
        style={{ minWidth: "18rem", minHeight: "8rem" }}
      >
        <div className="card-body d-flex flex-nowrap">
          {images.map((image, index) => (
            <div
              className="card"
              key={index}
              style={{
                position: "relative",
                padding: "10px",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              <i
                className="bi bi-x-circle"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  top: "-2px",
                  left: "2px",
                }}
                onClick={() => handleDeleteImage(index)}
              ></i>
              <img
                src={image}
                alt="..."
                style={{ width: "100px" }}
                className="img-thumbnail"
              />
            </div>
          ))}
        </div>
      </div>
      <input type="file" accept=".webp" multiple onChange={handleUploadImage} />
      <div className="d-flex" style={{ padding: "20px", gap: "20px" }}>
        <button className="btn btn-primary" onClick={handleSubmitPhotos}>
          {submitPhotosMutation.isLoading && (
            <span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;
            </span>
          )}
          Submit
        </button>
        {/* <button className=" btn btn-danger">Cancel</button> */}
      </div>
    </div>
  );
}

export default UploadPhotos;
