import React from "react";
import { useForm } from "react-hook-form";
import "./style.css";
import { login } from "../../services/auth";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import config from "../../config";
const Login = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const loginMutation = useMutation({
    mutationFn: login,
    onSuccess: () => {
      enqueueSnackbar("Login success!", { variant: "success" });
      navigate(config.routes.product);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });

  const handleSubmitForm = (values) => {
    loginMutation.mutate({
      username: values.username,
      password: values.password,
    });
  };

  return (
    <div className="login d-flex justify-content-center align-items-center vh-100 bg-primary">
      <div className="form_container p-5 rounded bg-white">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <h3 className="text-center">Sign In</h3>
          <div className="mb-2">
            <label htmlFor="email">Email</label>
            <input
              // type="email"
              placeholder="Enter Email"
              className="form-control"
              {...register("username")}
            ></input>
          </div>

          <div className="mb-2">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              placeholder="Enter Password"
              className="form-control"
              {...register("password")}
            ></input>
          </div>

          <div className="mb-2">
            <input
              type="checkbox"
              className="custom-control custom-checkbox"
            ></input>
            <label htmlFor="check" className="custom-input-label ms-2">
              Remember me
            </label>
          </div>

          <div className="d-grid">
            <button className="btn btn-primary">
              {loginMutation.isLoading && (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              &nbsp; Sign in
            </button>
          </div>
          <p className="text-end mt-2">
            <a href={config.routes.forgotPass}> Forgot Password</a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
