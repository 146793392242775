import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  getCategoryDataSheetList,
  getCategoryImageList,
  getCategoryList,
  getCategorySmallImageList,
} from "../../services/categories";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";
import { addProduct } from "../../services/products";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { quillModules, quillFormats } from "../../utils";

function AddProduct() {
  const [categoryId, setCategoryId] = useState(null);

  // Specifications
  const [specifications, setSpecifications] = useState([]);
  const [specificationCount, setSpecificationCount] = useState([]);

  // Photos
  const [photosList, setPhotosList] = useState([]);
  const [smallPhoto, setSmallPhoto] = useState(null);
  const [dataSheet, setDataSheet] = useState(null);

  // full description
  const [desc, setDesc] = useState("");

  const [currentImagePage, setCurrentImagePage] = useState(1);
  const [currentSmallImagePage, setCurrentSmallImagePage] = useState(1);
  const [currentDatasheetPage, setCurrentDatasheetPage] = useState(1);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });

  const categoryListQuery = useQuery({
    queryKey: ["categoryList-add"],
    queryFn: getCategoryList,
    staleTime: Infinity,
    onSuccess(data) {
      setCategoryId(data[0].category_id);
    },
  });

  const categoryList = categoryListQuery.data || [];

  const categoryImageListQuery = useQuery({
    queryKey: ["categoryImageList", categoryId, currentImagePage],
    queryFn: () => getCategoryImageList({ categoryId, page: currentImagePage }),
    staleTime: Infinity,
  });

  const categoryImageList = categoryImageListQuery.data || [];
  const hasImages = categoryImageList.length;

  const categorySmallImageListQuery = useQuery({
    queryKey: ["categorySmallImageList", categoryId, currentSmallImagePage],
    queryFn: () =>
      getCategorySmallImageList({ categoryId, page: currentSmallImagePage }),
    staleTime: Infinity,
  });

  const categorySmallImageList = categorySmallImageListQuery.data || [];
  const hasSmallImages = categorySmallImageList.length;

  const categoryDataSheetListQuery = useQuery({
    queryKey: ["categoryDataSheetList", categoryId, currentDatasheetPage],
    queryFn: () =>
      getCategoryDataSheetList({ categoryId, page: currentDatasheetPage }),
    staleTime: Infinity,
  });

  const categoryDataSheetList = categoryDataSheetListQuery.data || [];
  const hasDatasheet = categoryDataSheetList.length;

  const addProductMutation = useMutation({
    mutationFn: addProduct,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Add Product success!", { variant: "success" });
      navigate(config.routes.uploadProductImage, {
        state: { productId: data.product_id },
      });
    },
  });

  const handleSubmitForm = (values) => {
    const specs = specifications.reduce((accumulator, currentObject) => {
      return { ...accumulator, ...currentObject };
    }, {});
    const addData = {
      category_id: categoryId,
      part_number: values.part_number,
      description_short: values.description_short,
      description_full: desc,
      specifications: specs,
      lead_time_w: values.lead_time_w,
      photos: photosList,
      photo_small_id: smallPhoto,
      datasheet_id: dataSheet,
    };
    addProductMutation.mutate(addData);
  };

  const handleSelectCategory = (event) => {
    const id = event.target.value;
    setCategoryId(id);
  };

  const handleAddSpecification = () => {
    setSpecifications((prev) => [...prev, {}]);
    setSpecificationCount((prev) => [...prev, new Date().getTime()]);
  };

  const handleRemoveSpecification = (index) => {
    specifications.splice(index, 1);
    specificationCount.splice(index, 1);
    setSpecifications([...specifications]);
    setSpecificationCount([...specificationCount]);
  };

  const handleChangeSpecificationAttribute = (event, index) => {
    const newAttribute = event.target.value;
    const spec = specifications[index];
    const oldAttribute = Object.keys(spec)[0];
    spec[newAttribute] = spec[oldAttribute];
    delete spec[oldAttribute];
    specifications[index] = spec;
    setSpecifications([...specifications]);
  };

  const handleChangeSpecificationValue = (event, index) => {
    const newValue = event.target.value;
    const spec = specifications[index];
    const attribute = Object.keys(spec)[0];

    spec[attribute] = newValue;

    specifications[index] = spec;
    setSpecifications([...specifications]);
  };

  const handleChooseImage = (isChecked, index) => {
    const list = photosList;
    if (isChecked) {
      list.push(index);
      setPhotosList(list);
    } else {
      const newList = list.filter((item) => item !== index);
      setPhotosList(newList);
    }
  };

  const handleChooseSmallImage = (isChecked, index) => {
    if (isChecked) {
      setSmallPhoto(index);
    } else {
      setSmallPhoto(null);
    }
  };

  const handleChooseDatasheet = (isChecked, index) => {
    if (isChecked) {
      setDataSheet(index);
    } else {
      setDataSheet(null);
    }
  };

  // Choose Image Page
  const handleIncreaseImagePage = () => {
    if (hasImages) {
      setCurrentImagePage((prev) => prev + 1);
    }
  };

  const handleDecreaseImagePage = () => {
    if (currentImagePage > 1) {
      setCurrentImagePage((prev) => prev - 1);
    }
  };

  const handleChooseImagePage = (page) => {
    if (hasImages || page < currentImagePage) {
      setCurrentImagePage(page);
    }
  };

  // Choose Small Image Page
  const handleIncreaseSmallImagePage = () => {
    if (hasSmallImages) {
      setCurrentSmallImagePage((prev) => prev + 1);
    }
  };

  const handleDecreaseSmallImagePage = () => {
    if (currentSmallImagePage > 1) {
      setCurrentSmallImagePage((prev) => prev - 1);
    }
  };

  const handleChooseSmallImagePage = (page) => {
    if (hasSmallImages || page < currentSmallImagePage) {
      setCurrentSmallImagePage(page);
    }
  };

  // Choose datasheet Page
  const handleIncreaseDatasheetPage = () => {
    if (hasDatasheet) {
      setCurrentDatasheetPage((prev) => prev + 1);
    }
  };

  const handleDecreaseDatasheetPage = () => {
    if (currentDatasheetPage > 1) {
      setCurrentDatasheetPage((prev) => prev - 1);
    }
  };

  const handleChooseDatasheetPage = (page) => {
    if (hasDatasheet || page < currentDatasheetPage) {
      setCurrentDatasheetPage(page);
    }
  };

  return (
    <div>
      <form
        style={{ maxWidth: "600px" }}
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <h3 className="text-center">Add Product</h3>
        <div className="form-group row p-1">
          <label htmlFor="category_id" className="col-sm-4 col-form-label">
            Category:
          </label>
          <div className="col-sm-12">
            <select
              className="form-select"
              id="category_id"
              name="category_id"
              value={categoryId}
              required
              onChange={handleSelectCategory}
            >
              {categoryList.map((op) => (
                <option key={op.category_id} value={op.category_id}>
                  {op.category_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row p-1">
          <label htmlFor="partNumber" className="col-sm-4 col-form-label">
            Part Number:
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              id="partNumber"
              name="partNumber"
              type="text"
              required
              {...register("part_number")}
            />
          </div>
        </div>
        <div className="form-group row p-1">
          <label
            htmlFor="description_short"
            className="col-sm-4 col-form-label"
          >
            Short Description:
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              id="description_short"
              name="description_short"
              type="text"
              {...register("description_short")}
              required
            />
          </div>
        </div>
        <div className="form-group row p-1">
          <label htmlFor="description_full" className="col-sm-4 col-form-label">
            Full Description:
          </label>
          <div className="col-sm-12">
            <ReactQuill
              className="form-control"
              theme="snow"
              modules={quillModules}
              formats={quillFormats}
              id="description_full"
              name="description_full"
              value={desc}
              onChange={(value) => {
                setDesc(value);
              }}
            />
          </div>
        </div>
        <div className="form-group row p-1">
          <label htmlFor="specifications" className="col-sm-4 col-form-label">
            Specifications:
          </label>
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleAddSpecification}
            >
              Add Specifications
            </button>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Attribute</th>
                  <th scope="col">Value</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {specificationCount.map((id, index) => (
                  <tr key={id}>
                    <td>
                      <input
                        value={specifications[index].attribute}
                        className="form-control"
                        id={`spec_attr_${index}`}
                        name={`spec_attr_${index}`}
                        onChange={(event) =>
                          handleChangeSpecificationAttribute(event, index)
                        }
                        required
                      />
                    </td>
                    <td>
                      <input
                        value={specifications[index].value}
                        className="form-control"
                        id={`spec_value_${index}`}
                        name={`spec_value_${index}`}
                        onChange={(event) =>
                          handleChangeSpecificationValue(event, index)
                        }
                        required
                      />
                    </td>

                    <td>
                      <i
                        className="bi bi-trash3"
                        onClick={() => handleRemoveSpecification(index)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="form-group row p-1">
          <label htmlFor="leadTime" className="col-sm-4 col-form-label">
            Lead time (week):
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              id="leadTime"
              name="leadTime"
              type="number"
              {...register("lead_time_w")}
              required
            />
          </div>
        </div>
        <div className="form-group row p-1">
          <label htmlFor="photos" className="col-sm-4 col-form-label">
            Photos:
          </label>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body d-flex flex-wrap">
                {categoryImageList.map((image) => (
                  <div
                    key={image.photo_id}
                    className="card"
                    style={{
                      position: "relative",
                      width: "fit-content",
                      minWidth: "100px",
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={image.photo_id}
                      name={image.photo_id}
                      onChange={(event) =>
                        handleChooseImage(event.target.checked, image.photo_id)
                      }
                      style={{
                        position: "absolute",
                        right: "5px",
                      }}
                    ></input>
                    <img
                      src={image.url}
                      alt="..."
                      style={{ width: "100px" }}
                      className="img-thumbnail"
                    />
                  </div>
                ))}
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentImagePage === 1 && "disabled"
                    }`}
                    onClick={handleDecreaseImagePage}
                  >
                    <a className="page-link " href="#">
                      Previous
                    </a>
                  </li>
                  <li
                    className="page-item"
                    onClick={() =>
                      handleChooseImagePage(
                        currentImagePage === 1 ? 1 : currentImagePage - 1
                      )
                    }
                  >
                    <a
                      className={`page-link ${
                        currentImagePage === 1 && "active"
                      }`}
                      href="#"
                    >
                      {currentImagePage === 1 ? "1" : currentImagePage - 1}
                    </a>
                  </li>
                  <li
                    className="page-item"
                    onClick={() =>
                      handleChooseImagePage(
                        currentImagePage === 1 ? 2 : currentImagePage
                      )
                    }
                  >
                    <a
                      className={`page-link ${
                        currentImagePage !== 1 && "active"
                      }`}
                      href="#"
                    >
                      {currentImagePage === 1 ? "2" : currentImagePage}
                    </a>
                  </li>
                  <li
                    className="page-item"
                    onClick={() =>
                      handleChooseImagePage(
                        currentImagePage === 1 ? 3 : currentImagePage + 1
                      )
                    }
                  >
                    <a
                      className={`page-link ${!hasImages && "disabled"}`}
                      href="#"
                    >
                      {currentImagePage === 1 ? "3" : currentImagePage + 1}
                    </a>
                  </li>
                  <li
                    className={`page-item ${!hasImages && "disabled"}`}
                    onClick={handleIncreaseImagePage}
                  >
                    <a className="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="form-group row p-1">
          <label htmlFor="photo_small" className="col-sm-4 col-form-label">
            Small photos:
          </label>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body d-flex flex-wrap">
                {categorySmallImageList.map((image) => (
                  <div
                    key={image.photo_small_id}
                    className="card"
                    style={{
                      position: "relative",
                      width: "fit-content",
                      minWidth: "100px",
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={image.photo_small_id}
                      name={image.photo_small_id}
                      onChange={(event) =>
                        handleChooseSmallImage(
                          event.target.checked,
                          image.photo_small_id
                        )
                      }
                      style={{
                        position: "absolute",
                        right: "5px",
                      }}
                    ></input>
                    <img
                      src={image.url}
                      alt="..."
                      style={{ width: "100px" }}
                      className="img-thumbnail"
                    />
                  </div>
                ))}
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentSmallImagePage === 1 && "disabled"
                    }`}
                    onClick={handleDecreaseSmallImagePage}
                  >
                    <a className="page-link " href="#">
                      Previous
                    </a>
                  </li>
                  <li
                    className="page-item"
                    onClick={() =>
                      handleChooseSmallImagePage(
                        currentSmallImagePage === 1
                          ? 1
                          : currentSmallImagePage - 1
                      )
                    }
                  >
                    <a
                      className={`page-link ${
                        currentSmallImagePage === 1 && "active"
                      }`}
                      href="#"
                    >
                      {currentSmallImagePage === 1
                        ? "1"
                        : currentSmallImagePage - 1}
                    </a>
                  </li>
                  <li
                    className="page-item"
                    onClick={() =>
                      handleChooseSmallImagePage(
                        currentSmallImagePage === 1 ? 2 : currentSmallImagePage
                      )
                    }
                  >
                    <a
                      className={`page-link ${
                        currentSmallImagePage !== 1 && "active"
                      }`}
                      href="#"
                    >
                      {currentSmallImagePage === 1
                        ? "2"
                        : currentSmallImagePage}
                    </a>
                  </li>
                  <li
                    className="page-item"
                    onClick={() =>
                      handleChooseSmallImagePage(
                        currentSmallImagePage === 1
                          ? 3
                          : currentSmallImagePage + 1
                      )
                    }
                  >
                    <a
                      className={`page-link ${!hasSmallImages && "disabled"}`}
                      href="#"
                    >
                      {currentSmallImagePage === 1
                        ? "3"
                        : currentSmallImagePage + 1}
                    </a>
                  </li>
                  <li
                    className={`page-item ${!hasSmallImages && "disabled"}`}
                    onClick={handleIncreaseSmallImagePage}
                  >
                    <a className="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="form-group row p-1">
          <label htmlFor="datasheet" className="col-sm-4 col-form-label">
            Datasheet:
          </label>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body d-flex flex-wrap">
                {categoryDataSheetList.map((pdf) => (
                  <div
                    key={pdf.datasheet_id}
                    className="card"
                    id={pdf.datasheet_id}
                    name={pdf.datasheet_id}
                    onChange={(event) =>
                      handleChooseDatasheet(
                        event.target.checked,
                        pdf.datasheet_id
                      )
                    }
                    style={{
                      position: "relative",
                      width: "100px",
                      height: "120px",
                      padding: "5px",
                      "text-overflow": "ellipsis",
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      style={{
                        position: "absolute",
                        right: "5px",
                      }}
                    ></input>
                    <a href={pdf.url}>{pdf.file_name}</a>
                  </div>
                ))}
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentDatasheetPage === 1 && "disabled"
                    }`}
                    onClick={handleDecreaseDatasheetPage}
                  >
                    <a className="page-link " href="#">
                      Previous
                    </a>
                  </li>
                  <li
                    className="page-item"
                    onClick={() =>
                      handleChooseDatasheetPage(
                        currentDatasheetPage === 1
                          ? 1
                          : currentDatasheetPage - 1
                      )
                    }
                  >
                    <a
                      className={`page-link ${
                        currentDatasheetPage === 1 && "active"
                      }`}
                      href="#"
                    >
                      {currentDatasheetPage === 1
                        ? "1"
                        : currentDatasheetPage - 1}
                    </a>
                  </li>
                  <li
                    className="page-item"
                    onClick={() =>
                      handleChooseDatasheetPage(
                        currentDatasheetPage === 1 ? 2 : currentDatasheetPage
                      )
                    }
                  >
                    <a
                      className={`page-link ${
                        currentDatasheetPage !== 1 && "active"
                      }`}
                      href="#"
                    >
                      {currentDatasheetPage === 1 ? "2" : currentDatasheetPage}
                    </a>
                  </li>
                  <li
                    className="page-item"
                    onClick={() =>
                      handleChooseDatasheetPage(
                        currentDatasheetPage === 1
                          ? 3
                          : currentDatasheetPage + 1
                      )
                    }
                  >
                    <a
                      className={`page-link ${!hasDatasheet && "disabled"}`}
                      href="#"
                    >
                      {currentDatasheetPage === 1
                        ? "3"
                        : currentDatasheetPage + 1}
                    </a>
                  </li>
                  <li
                    className={`page-item ${!hasDatasheet && "disabled"}`}
                    onClick={handleIncreaseDatasheetPage}
                  >
                    <a className="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center p-2"
          style={{ gap: "10px" }}
        >
          <button type="submit" className="btn btn-primary">
            {addProductMutation.isLoading && (
              <span>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                &nbsp;
              </span>
            )}
            Submit
          </button>
          <button type="button" className=" btn btn-danger">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddProduct;
